import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container, TextField, Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography,
  Select, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, TablePagination
} from '@mui/material';
import API_BASE_URL from './Api';
import { useCallback } from 'react';

const GetEnquiry = () => {
  const [enquiryData, setEnquiryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTechnology, setSearchTechnology] = useState('');
  const [statusOptions] = useState(['Called', 'Not Called']);
  const [enquiryModeOptions] = useState(['Online', 'Offline']);
  const [editMode, setEditMode] = useState(false);
  const [admissionStatusOptions] = useState(['Admitted', 'Pending', 'Rejected']);
  const [expandedMessage, setExpandedMessage] = useState({});
  const [editEnquiry, setEditEnquiry] = useState({
    id: '',
    name: '',
    phoneNo: '',
    email: '',
    message: '',
    technology: '',
    call: '',
    callStatus: '',
    studentFeedback: '',
    enquiryMode: '',
    description: '',
    admissionStatus: '',
  });

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/auth/getallEnquiry`, {
        params: {
          page: page,
          limit: rowsPerPage,
        }, headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('API Response:', response.data); // Check the structure here
      setEnquiryData(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching data:', error);
      setEnquiryData([]);
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage]);
  
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearch = async () => {
    if (!searchTechnology) return;

    setLoading(true);

    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.get(
        `${API_BASE_URL}/auth/searchEnquiryByTechnology`, {
          params: {
            technology: searchTechnology,
            page: page,
            limit: rowsPerPage,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('Search Response:', response.data); // Check the structure here
      setEnquiryData(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching enquiry data:', error);
      setEnquiryData([]);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (id) => {
    const enquiryToEdit = enquiryData.find(item => item.id === id);
    if (enquiryToEdit) {
      setEditEnquiry(enquiryToEdit);
      setEditMode(true);
    } else {
      console.error(`Enquiry with ID ${id} not found.`);
    }
  };

  const handleSave = async () => {
    setLoading(true); // Show loading indicator while saving
    try {
      const token = sessionStorage.getItem('token');
      await axios.put(
        `${API_BASE_URL}/auth/updateEnquiryById/${editEnquiry.id}`,
        editEnquiry,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('Enquiry updated successfully');
      await fetchData(); // Fetch updated data
      setEditMode(false);
      setEditEnquiry({
        id: '',
        name: '',
        phoneNo: '',
        email: '',
        message: '',
        technology: '',
        call: '',
        callStatus: '',
        studentFeedback: '',
        enquiryMode: '',
        description: '',
        admissionStatus: '',
      });
    } catch (error) {
      console.error('Error updating enquiry:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setEditEnquiry({
      ...editEnquiry,
      [e.target.name]: e.target.value,
    });
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setEditEnquiry({
      id: '',
      name: '',
      phoneNo: '',
      email: '',
      message: '',
      technology: '',
      call: '',
      callStatus: '',
      studentFeedback: '',
      enquiryMode: '',
      description: '',
      admissionStatus: '',
    });
  };

  const handleToggleMessage = (id) => {
    setExpandedMessage(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  // Function to determine row color based on call status
  const getStatusColor = (status) => {
    switch (status) {
      case 'Call received':
        return 'green';
      case 'Not received':
        return 'red';
      case 'Rejected':
        return 'orange';
      case 'Unreachable':
        return 'gray';
      default:
        return 'white';
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Enquiry Data
      </Typography>
      <div>
        <TextField
          label="Search by Technology"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchTechnology}
          onChange={(e) => setSearchTechnology(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          sx={{ mt: 2, mb: 4 }}
        >
          Search
        </Button>
      </div>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <TableContainer component={Paper} sx={{ mt: 4 }}>
            <Table className="table table-striped">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Phone No</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Technology</TableCell>
                  <TableCell>Call</TableCell>
                  <TableCell>Call Status</TableCell>
                  <TableCell>Student Feedback</TableCell>
                  <TableCell>Enquiry Mode</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Admission Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(enquiryData) && enquiryData.length > 0 ? (
                  enquiryData.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.phoneNo}</TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>
                        {item.message.length > 100 ? (
                          <>
                            {expandedMessage[item.id] ? item.message : `${item.message.substring(0, 100)}...`}
                            <Button onClick={() => handleToggleMessage(item.id)}>
                              {expandedMessage[item.id] ? 'Less' : 'More'}
                            </Button>
                          </>
                        ) : (
                          item.message
                        )}
                      </TableCell>
                      <TableCell>{item.technology}</TableCell>
                      <TableCell>{item.status}</TableCell>
                      <TableCell style={{ backgroundColor: getStatusColor(item.callStatus) }}>
                        {item.callStatus}
                      </TableCell>
                      <TableCell>{item.studentFeedback}</TableCell>
                      <TableCell>{item.enquiryMode}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.admissionStatus}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleEdit(item.id)}
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>No data available</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={enquiryData.length} // Adjust this if you have total count
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </TableContainer>

          <Dialog open={editMode} onClose={handleCancelEdit}>
            <DialogTitle>Edit Enquiry</DialogTitle>
            <DialogContent>
              <TextField
                margin="dense"
                label="Name"
                name="name"
                value={editEnquiry.name}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                margin="dense"
                label="Phone No"
                name="phoneNo"
                value={editEnquiry.phoneNo}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                margin="dense"
                label="Email"
                name="email"
                value={editEnquiry.email}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                margin="dense"
                label="Message"
                name="message"
                value={editEnquiry.message}
                onChange={handleChange}
                fullWidth
                multiline
                rows={4}
              />
              <TextField
                margin="dense"
                label="Technology"
                name="technology"
                value={editEnquiry.technology}
                onChange={handleChange}
                fullWidth
              />
           <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel>Call</InputLabel>
               <Select
                  name="status"
                  value={editEnquiry.status}
                  onChange={handleChange}
                  label="Status"
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
             </FormControl>
              <FormControl fullWidth margin="dense">
                <InputLabel>Call Status</InputLabel>
                <Select
                  name="callStatus"
                  value={editEnquiry.callStatus}
                  onChange={handleChange}
                >
                  <MenuItem value="Call received">Call received</MenuItem>
                  <MenuItem value="Not received">Not received</MenuItem>
                  <MenuItem value="Rejected">Rejected</MenuItem>
                  <MenuItem value="Unreachable">Unreachable</MenuItem>
                </Select>
              </FormControl>
              <TextField
                margin="dense"
                label="Student Feedback"
                name="studentFeedback"
                value={editEnquiry.studentFeedback}
                onChange={handleChange}
                fullWidth
              />
              <FormControl fullWidth margin="dense">
                <InputLabel>Enquiry Mode</InputLabel>
                <Select
                  name="enquiryMode"
                  value={editEnquiry.enquiryMode}
                  onChange={handleChange}
                >
                  {enquiryModeOptions.map((mode) => (
                    <MenuItem key={mode} value={mode}>
                      {mode}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                margin="dense"
                label="Description"
                name="description"
                value={editEnquiry.description}
                onChange={handleChange}
                fullWidth
                multiline
                rows={4}
              />
              <FormControl fullWidth margin="dense">
                <InputLabel>Admission Status</InputLabel>
                <Select
                  name="admissionStatus"
                  value={editEnquiry.admissionStatus}
                  onChange={handleChange}
                >
                  {admissionStatusOptions.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelEdit} color="secondary">
                Cancel
              </Button>
              <Button onClick={handleSave} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Container>
  );
};

export default GetEnquiry;

