import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Card } from 'react-bootstrap';
import './ProductForm.css';
import axios from 'axios';
import API_BASE_URL from '../Api';

const ProductCategory = () => {
  const [formData, setFormData] = useState({
    productName: '',
    category: ''
  });

  const [response, setResponse] = useState(null);
  const [products, setProducts] = useState([]);
  const [productIdMap, setProductIdMap] = useState({});

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const result = await axios.get(`${API_BASE_URL}/products/getAllProducts`);
        console.log(result.data); // Log the result to check its structure
        if (result.data && Array.isArray(result.data)) {
          setProducts(result.data);
          // Create a mapping of product names to their IDs
          const idMap = result.data.reduce((map, product) => {
            map[product.productName] = product.id;
            return map;
          }, {});
          setProductIdMap(idMap);
        }
      } catch (error) {
        console.error('Error fetching products:', error);
        alert('Failed to fetch products. Please try again later.');
      }
    };

    fetchProducts();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.productName || !formData.category) {
      alert('Please fill in all required fields.');
      return;
    }

    const productId = productIdMap[formData.productName];
    if (!productId) {
      alert('Invalid product selected.');
      return;
    }

    try {
      console.log('category:'+formData.category+" product Id:"+productId);
      const response = await axios.post(`${API_BASE_URL}/product-categories/addCategory`, {
        category: formData.category,
        productInformation:{id:productId} // Use the exact column name here
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log(response); // Log the full response to understand its structure

      if (response && response.data) {
        alert(response.data.message || 'Operation successful');
        setFormData({
          productName: '',
          category: ''
        });
        setResponse({ message: response.data.message || 'Operation successful' });
      } else {
        alert('Unexpected response structure.');
      }
    } catch (error) {
      console.error('Error submitting product form:', error);
      alert('Failed to submit product form. Please try again later.');
      setResponse({ message: 'Submission failed. Please try again later.' });
    }
  };

  return (
    <Container className="product-form-container">
      <Card className="product-form-card">
        <Card.Body>
          <Card.Title>Product Category</Card.Title>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formProduct">
              <Form.Label className="form-label">Select Product</Form.Label>
              <Form.Select
                name="productName"
                value={formData.productName}
                onChange={handleChange}
                required
              >
                <option value="">Select Product</option>
                {products.map(product => (
                  <option key={product.id} value={product.productName}>{product.productName}</option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formCategory">
              <Form.Label className="form-label">Product Category</Form.Label>
              <Form.Control
                type="text"
                name="category"
                value={formData.category}
                onChange={handleChange}
                placeholder="Enter category"
                required
              />
            </Form.Group>

            <div className='mt-4'>
              <Button variant="primary" className='submit-btn' type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ProductCategory;

