import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

const Courses = () => {
  const [, setClicked] = useState(false);
  const [expandedCards, setExpandedCards] = useState({});

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setClicked(true);
  };

  const handleReadMoreToggle = (cardId) => {
    setExpandedCards((prevExpandedCards) => ({
      ...prevExpandedCards,
      [cardId]: !prevExpandedCards[cardId]
    }));
  };

  const coursesData = [
    {
      id: 1,
      title: "Full Stack Java + Full Stack MERN(Free)",
      imgSrc: "image/java.png",
      description: [
        "Back-End: Core Java, Advance Java, JSP, Servlet, Hibernate, Spring, SpringBoot, MicroServices RESTful APIs, SQL/NoSQL databases",
        "Front-End: HTML, CSS, JavaScript, React/Angular/Vue",
        "Tools: Git, Eclipse, STS, Maven/Gradle, CI/CD (Jenkins)"
      ],
      shortDescription: [
        "Back-End: Core Java, Advance Java, JSP, Servlet, Hibernate..."
      ],
      link: "/java"
    },
    {
      id: 2,
      title: "Full Stack Python + Full Stack MERN(Free)",
      imgSrc: "image/python.png",
      description: [
        "Back-End: Core Python, Advance Python, Django/Flask, SQLAlchemy, RESTful APIs, SQL/NoSQL databases",
        "Front-End: HTML, CSS, JavaScript, React/Angular/Vue",
        "Tools: Git, pip/conda",
        "Machine Learning Libraries: Scikit-Learn, TensorFlow, PyTorch, Keras",
        "Data Science Libraries: Pandas, NumPy, SciPy, Matplotlib, Seaborn, Plotly"
      ],
      shortDescription: [
        "Back-End: Core Python, Advance Python, Django/Flask, SQLAlchemy..."
      ],
      link: "/python"
    },
    {
      id: 3,
      title: "Full Stack MERN ",
      imgSrc: "image/MernStack.png",
      description: [
        "Back-End: Node.js, Express.js, MongoDB, RESTful APIs",
        "Front-End: React, HTML, CSS, JavaScript",
        "Tools: Git, npm/yarn, CI/CD (Jenkins)",
        "Skills: Testing (Jest), security best practices, problem-solving, team collaboration"
      ],
      shortDescription: [
        "Back-End: Node.js, Express.js, MongoDB, RESTful APIs..."
      ],
      link: "/mern"
    }
  ];

  return (
    <div>
      <div className="container-fluid page-header py-6 my-6 mt-0 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">Courses</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
              {/* <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li> */}
              <li className="breadcrumb-item text-primary active" aria-current="page">Courses</li>
            </ol>
          </nav>
        </div>
      </div>
      
      <div className="container-xxl courses my-6 py-6 pb-0">
      <h5 className='marquee text-dark'>
            <div>NOTE: Enrollment in the Fullstack Java course OR Fullstack Python course (you’ll also get the Fullstack MERN course for free!)</div>
        </h5>
        <div className="container">
          <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "500px" }}>
            <h6 className="text-primary text-uppercase mb-2">Our Courses</h6>
          </div>
          <div className="row g-4 justify-content-center">
            {coursesData.map(course => (
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s" key={course.id}>
                <div className="courses-item d-flex flex-column bg-white overflow-hidden">
                  <div className="position-relative mt-auto">
                    <img className="img-fluid" src={course.imgSrc} alt="" width='80px' />
                    <div className="courses-overlay">
                      <Link className="btn btn-outline-primary border-2" to={course.link} onClick={handleScrollToTop}>Read More</Link>
                    </div>
                  </div>
                  <div className="text-center p-4 pt-0">
                    <h6>{course.title}</h6>
                    <p>Certification</p>
                    <ol className="breadcrumb justify-content-center mb-0">
                      <li className="breadcrumb-item small"><i className="fa fa-signal text-primary me-2"></i>Beginner</li>
                      <li className="breadcrumb-item small"><i className="fa fa-calendar-alt text-primary me-2"></i>3 Week</li>
                    </ol>
                    <p><b>Description</b></p>
                    <ul className='text-start'>
                      {(expandedCards[course.id] ? course.description : course.shortDescription).map((desc, index) => (
                        <li key={index}>{desc}</li>
                      ))}
                    </ul>
                    <Button variant="link" onClick={() => handleReadMoreToggle(course.id)}>
                      {expandedCards[course.id] ? "Read Less" : "Read More"}
                    </Button>
                  </div>
                  <div className='text-center mt-2'>
                    <Button className='mt-2 mb-4 booknow' size="sm" as={Link} to={course.link} onClick={handleScrollToTop} style={{ border: 'none' }}>Book Now</Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
