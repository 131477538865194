import React, { useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import API_BASE_URL from '../Api'; 
 import './UpcomingBatchForm.css'; 

const UpcomingBatchForm = () => {
  const [formData, setFormData] = useState({
    courseName: '',
    duration: '',
    date: '',
    time: '',
    modeOfTraining:'',
    enroll: ''
  });

  const [response, setResponse] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.post(`${API_BASE_URL}/auth/addUpComingBatch`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      alert( response.data.message);
      setFormData({
        courseName: '',
        duration: '',
        date: '',
        time: '',
        modeOfTraining:'',
        enroll: ''
      });
      setResponse({ message:   response.data.message});
    } catch (error) {
      console.error('Error submitting enquiry:', error);
      alert('Failed to submit form. Please try again later.');
      setResponse({ message:   response.data.message });
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md="8" className="mx-auto">
          <Card className="shadow p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title className="text-center mb-4">Upcoming Batch Form</Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="corseformName">
                  <Form.Label className='label'>Course Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="courseName"
                    value={formData.courseName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formDuration">
                  <Form.Label>Duration</Form.Label>
                  <Form.Control
                    type="number"
                    name="duration"
                    value={formData.duration}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formDate">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formTime">
                  <Form.Label>Time</Form.Label>
                  <Form.Control
                      type="time"
                    name="time"
                    value={formData.time}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formTime">
                  <Form.Label>Mode of Training</Form.Label>
                  <Form.Control
                      type="text"
                    name="modeOfTraining"
                    value={formData.modeOfTraining}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formEnroll">
                  <Form.Label >Enroll</Form.Label>
                  <Form.Control
                    type="text"
                    name="enroll"
                    value={formData.enroll}
                    onChange={handleChange}
                    required
                  >
                  </Form.Control>
                </Form.Group>

                <Button variant="primary" type="submit" className="mt-3 w-100 submitbtn">
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UpcomingBatchForm;

