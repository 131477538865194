import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Card } from 'react-bootstrap';
import axios from 'axios';
import './ProductForm.css';
import API_BASE_URL from '../Api';

const ProductSubCategory = () => {
  const [formData, setFormData] = useState({
    productName: '',
    category: '',
    productSubCategory: ''
  });

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Fetch products from backend
    axios.get(`${API_BASE_URL}/products/getAllProducts`)
      .then(response => {
        console.log("Fetched products:", response.data);
        setProducts(response.data); // Assuming response.data is an array of products
      })
      .catch(error => {
        console.error('Error fetching products:', error);
        alert('Failed to fetch products. Please try again later.');
      });
  }, []);

  useEffect(() => {
    // Fetch categories when a product is selected
    if (formData.productName) {
      axios.get(`${API_BASE_URL}/product-subcategories/distinct-categories`, {
        params: { productId: formData.productName }
      })
      .then(response => {
        console.log("Fetched Categories:", response.data);
        setCategories(response.data); // Assuming response.data is an array of categories
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
        alert('Failed to fetch categories. Please try again later.');
      });
    } else {
      setCategories([]); // Clear categories if no product is selected
    }
  }, [formData.productName]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const categoryId = formData.category;
    const productSubCategory = formData.productSubCategory;

    // Check if both categoryId and productSubCategory are present
    if (!categoryId || !productSubCategory) {
      alert('Please select a category and enter a subcategory.');
      return;
    }

    // Optional: Add a console log to check the data being sent
    console.log('Submitting data:', {
      productSubCategory,
      productCategory: {
        id: categoryId
      }
    });

    try {
      const response = await axios.post(`${API_BASE_URL}/product-subcategories/addSubCategory`, {
        productSubCategory,
        productCategory: {
          id: categoryId
        }
      }, 
      {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response && response.data) {
        alert(response.data.message || 'Subcategory added successfully');
        setFormData({
          productName: '',
          category: '',
          productSubCategory: ''
        });
      } else {
        alert('Unexpected response structure.');
      }
    } catch (error) {
      console.error('Error submitting subcategory form:', error);
      alert('Failed to submit subcategory form. Please try again later.');
    }
  };

  return (
    <Container className="product-form-container">
      <Card className="product-form-card">
        <Card.Body>
          <Card.Title>Product SubCategory</Card.Title>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formProduct">
              <Form.Label className="form-label">Select Product</Form.Label>
              <Form.Select
                name="productName"
                value={formData.productName}
                onChange={handleChange}
                required
              >
                <option value="">Select Product</option>
                {products.length > 0 ? (
                  products.map(product => (
                    <option key={product.id} value={product.id}>
                      {product.productName}
                    </option>
                  ))
                ) : (
                  <option value="">No products available</option>
                )}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formCategory">
              <Form.Label className="form-label">Product Category</Form.Label>
              <Form.Select
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
              >
                <option value="">Select Category</option>
                {categories.length > 0 ? (
                  categories.map(category => (
                    <option key={category.id} value={category.id}>
                      {category} {/* Ensure this matches the data structure */}
                    </option>
                  ))
                ) : (
                  <option value="">No categories available</option>
                )}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formSubCategory">
              <Form.Label className="form-label">Product SubCategory</Form.Label>
              <Form.Control
                type="text"
                name="productSubCategory"
                value={formData.productSubCategory}
                onChange={handleChange}
                placeholder="Enter subcategory"
                required
              />
            </Form.Group>

            <div className="mt-4">
              <Button variant="primary" className="submit-btn" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ProductSubCategory;

