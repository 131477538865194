import './JavaPage.css';
import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Card, Row,Col,Accordion } from 'react-bootstrap';
import { useState } from 'react';
import { BiPlusMedical } from "react-icons/bi";
import { FaCertificate, FaUserGraduate, FaBriefcase, FaProjectDiagram, FaChalkboardTeacher, FaHandshake, FaTasks, FaClock, FaStar } from 'react-icons/fa';
const PythonPage = () => {
  const [, setClicked] = useState(false);

  const handleScrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setClicked(true); // Set clicked to true to trigger re-rendering
    };
 
  return (
    <div>
      {/* page header start*/}
       <div className="container-fluid page-header py-6 my-6 mt-0 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center">
            <h1 className="display-5 text-white animated slideInDown mt-4 mb-4">PYTHON TRAINING INSTITUTE IN PUNE</h1>
            <h5 className='marquee  text-white'>
            <div>NOTE: Enrollment in the  Fullstack Python course (you’ll also get the Fullstack MERN course for free!)</div>
        </h5>
      </div>
      </div>
      {/* page header end */}
      {/* course overview start */}
            <div className="course-info mt-0">
                <h2 className='about-head'>FullStack Python Course Overview</h2>
                <BiPlusMedical size={30}/>
                <h2 className="  head1 animated slideInDown mt-2 mb-4 text-decoration-underline">Full Stack "MERN" (Free)</h2>
                <h5>NOTE : Enrollment in the Fullstack Python course ( you’ll also get the Fullstack MERN course for free!)</h5>
                <Container fluid className="display-text-container">
   
          <p className='course-overview'>
          This comprehensive Python programming course is designed to cater to both beginners and experienced developers who want to learn and enhance their Python programming skills. Whether you are completely new to programming or already have some experience with other languages, this course will provide you with a solid foundation in Python and help you develop proficiency in the language.
          </p>
     
    </Container>
                <br></br>
                <div className="enroll-now">
          <Link to='/enquiry'>
            <button className="enroll-now-button" onClick={handleScrollToTop}>Enroll Now</button>
          </Link>
          </div>
          </div>
          {/* course overview end */}
          {/* key highlights start */}
          <div className="key-highlights">
                <h2 className='head1'><b>Key Highlights</b></h2>
                <div className="highlight-grid">
                    <div className="highlight-item">
                        <FaCertificate size={80} />
                        <p>Certification</p>
                    </div>
                    <div className="highlight-item">
                        <FaUserGraduate size={80} />
                        <p>Mentor Support</p>
                    </div>
                    <div className="highlight-item">
                        <FaBriefcase size={80} />
                        <p>100% Job Assistance</p>
                    </div>
                    <div className="highlight-item">
                        <FaProjectDiagram size={80} />
                        <p>Projects and Assignments</p>
                    </div>
                    <div className="highlight-item">
                        <FaChalkboardTeacher size={80} />
                        <p>Interview Preparation</p>
                    </div>
                    <div className="highlight-item">
                        <FaHandshake size={80} />
                        <p>Hands-on Experience</p>
                    </div>
                    <div className="highlight-item">
                        <FaTasks size={80} />
                        <p>Comprehensive Coverage</p>
                    </div>
                    <div className="highlight-item">
                        <FaClock size={80} />
                        <p>Flexible Learning Options</p>
                    </div>
                </div>
            </div>
              {/* key highlights end */}
                {/* learn java  start */}
            <Container fluid className="display-text-container">
       <Card className="display-text-card">
        <Card.Body> 
            <div className="java-card">
                <h2 className='about-head'><b>Learn Python</b></h2>
                <p>Python is a high-level, object-oriented programming language.
          It is popularly used in a wide range of applications, from web development to machine learning.
          Learning Python course in Pune can be beneficial for you, whether you are a student, a professional, or a business owner.
          It adds value to your resume and can open new career opportunities.</p>
                <div className="rating">
                    <p>5.0</p>
                    <div className="stars">
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                    </div>
                    <p>332 reviews</p>
                </div>
                <div className="enroll-now">
                    <Link to='/enquiry'>
                    <button className="enroll-now-button" onClick={handleScrollToTop}>Enquiry Now</button>
                    </Link>
                   
                </div>
            </div>
          </Card.Body>
          </Card>
          </Container>
            {/* learn java end */}
            {/* java course overview start */}
            <div className="container-xxl py-6">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                    {/* <h6 className="text-primary text-uppercase mb-2">Why Choose Us!</h6> */}
                    <h1 className="head1 display-6 mb-4">Python Course Overview</h1>
                    <p className="course-overview">
                    Python is powerful and the world’s fastest-growing programming language.
              It is widely used in various industries, including IT, finance, healthcare, and education.
              Its simplicity, usability, and versatility have helped it become very popular in recent years.
              Enrolling in a Python training course is an excellent place to start if you want to learn the language.   At Vtech  Academy, we cover a wide range of topics in our Python basic to advance course in Pune, including
              the fundamentals of Python, data structures, algorithms, object-oriented programming, web development, and data science.
              Our trainers teach Python using a practical, hands-on method that includes several coding exercises, projects, and case studies.
              You will have a solid understanding of the language and be able to create Python programs on your own by the time our Python training course is over.
                    </p>
                    {/* <p className="mb-5">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore erat amet</p> */}
                    <div className="row gy-5 gx-4">
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0 text-start">Python programming fundamentals</h5>
                            </div>
                         
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.2s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">Data manipulation and analysis</h5>
                            </div>
                           
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">Web scraping and automation</h5>
                            </div>
                          
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">Object-oriented programming</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className=" icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">Working with APIs and JSON data</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">GUI development</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">Data visualization</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0 text-start">Database integration</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0 text-start">Error handling and exception handling</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">Introduction to machine learning and data science</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0 blink">Live Industry Projects Working</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0 blink">Internship(Free)</h5>
                            </div>
                       
                        </div>

                    </div>
                    
                </div>
                </div>
                </div>
                </div>     
                   {/* java course overview end */}
                      {/* course objective start */}
     <Container fluid className="course-objectives-container">
      <div className='course-objective'>
      <h2 className='about-head'>Course Objectives</h2>
      </div>
      
      <Row className="justify-content-center mt-4">
      
          <Card className="basic-java-card shadow" style={{ width: '70rem' }}>
            <Card.Body>
              <h1 className='head1'>BASIC OF PYTHON</h1>
              <hr />
              <h4>Course Duration: 60 HOURS</h4>
              <img src='./image1/python.png' alt='Python Logo' width='70px' />
              <div className="skills-list ">
                  <Accordion>
                     <Accordion.Item eventKey="0">
                      <Accordion.Header><b> Introduction To Python</b></Accordion.Header>
                      <Accordion.Body style={{ textAlign: 'left' }}>
                        <ul style={{ paddingLeft: 10 }}>
                       <li> Why Python , its Unique Feature and where to use it?</li>
                        <li> Python environment Setup</li>
                        <li> Discuss about IDE’s like IDLE, Pycharm and Enthought Canopy</li>
                        <li> Start programming on interactive shell.</li>
                        <li> Python Identifiers, Keywords</li>
                        <li> Discussion about installed module s and packages</li>
                        <li> Access Command line arguments within programs</li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header><b>Python Data Types</b></Accordion.Header>
                      <Accordion.Body style={{ textAlign: 'left' }}>
                        <ul style={{ paddingLeft: 10 }}>
                       <li>  Declaring and using Numeric data types: int, float, complex</li>
                       <li>  Using string data type and string operations</li>
                      <li>   Defining list and list slicing</li>
                      <li>   Use of Tuple data type</li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header><b>Python Exception Handling</b></Accordion.Header>
                      <Accordion.Body style={{ textAlign: 'left' }}>
                        <ul style={{ paddingLeft: 0 }}>
                        <li> Avoiding code break using exception handling</li>
                       <li>  Safe guarding file operation using exception handling</li>
                       <li>  Handling and helping developer with error code</li>
                       <li>  Programming using Exception handling</li>
                            </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header><b>Python Program Flow Control</b></Accordion.Header>
                      <Accordion.Body style={{ textAlign: 'left' }}>
                        <ul style={{ paddingLeft: 10 }}>
                      <li>   Conditional blocks using if, else and elif</li>
                      <li>   Simple for loops in python</li>
                      <li>   For loop using ranges, string, list and dictionaries</li>
                      <li>   Use of while loops in python</li>
                      <li>   Loop manipulation using pass, continue, break and else</li>
                      <li>   Programming using Python conditional and loops block</li>
                            </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header><b>Python Functions , Modules And Packages</b></Accordion.Header>
                      <Accordion.Body style={{ textAlign: 'left' }}>
                        <ul style={{ paddingLeft: 10 }}>
                     <li>    Organizing python codes using functions</li>
                     <li>    Organizing python projects into modules</li>
                     <li>    Importing own module as well as external modules</li>
                     <li>    Understanding Packages</li>
                      <li>   Powerful Lamda function in python</li>
                      <li>   Programming using functions,modules & external packages</li>
                            </ul>
                      </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="5">
                      <Accordion.Header><b>Python File Operation</b></Accordion.Header>
                      <Accordion.Body style={{ textAlign: 'left' }}>
                        <ul style={{ paddingLeft: 0 }}>
                      <li>   Reading config files in python</li>
                      <li>   Writing log files in python</li>
                      <li>   Understanding read functions, read(), readline() and readlines()</li>
                      <li>   Understanding write functions, write() and writelines()</li>
                       <li>  Manipulating file pointer using seek</li>
                       <li>  Programming using file operations</li>
                            </ul>
                      </Accordion.Body>
                    </Accordion.Item>  

                    <Accordion.Item eventKey="6">
                      <Accordion.Header><b> Python Object Oriented Programming – Oops</b></Accordion.Header>
                      <Accordion.Body style={{ textAlign: 'left' }}>
                        <ul style={{ paddingLeft: 0 }}>
                    
                       <li>  Concept of class, object and instances</li>
                       <li>  Constructor, class attributes and destructors</li>
                       <li>  Real time use of class in live projects</li>
                       <li>  Inheritance , overlapping and overloading operators</li>
                       <li>  Adding and retrieving dynamic attributes of classes</li>
                       <li>  Programming using Oops support</li>
                            </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="7">
                      <Accordion.Header><b> Machine Learning</b></Accordion.Header>
                      <Accordion.Body style={{ textAlign: 'left' }}>
                        <ul style={{ paddingLeft: 0 }}>
                    
                     <li>   Machine learning: the problem setting</li> 
                      <li>   Loading an example dataset</li>
                     <li>   Learning and predicting</li>
                     <li>    linear regression</li>
                      <li>  Decision tree implementation</li> 
                            </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="8">
                      <Accordion.Header><b> Contacting User Through Emails Using Python</b></Accordion.Header>
                      <Accordion.Body style={{ textAlign: 'left' }}>
                        <ul style={{ paddingLeft: 0 }}>
                    
                       <li>  Installing smtp python module</li>
                       <li> Sending email</li>
                       <li>  Reading from file and sending emails to all users addressing them directly for marketing</li>
                            </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="9">
                      <Accordion.Header><b> Python Database Interaction</b></Accordion.Header>
                      <Accordion.Body style={{ textAlign: 'left' }}>
                        <ul style={{ paddingLeft: 0 }}>
                    
                      <li>  SQL Database connection using python</li>
                      <li>   Creating and searching tables</li>
                      <li>   Reading and storing config information on database</li>
                      <li>   Programming using database connections</li>
                            </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="10">
                      <Accordion.Header><b> Data analysis</b></Accordion.Header>
                      <Accordion.Body style={{ textAlign: 'left' }}>
                        <ul style={{ paddingLeft: 0 }}>
                    
                       <li> Numpy Intro</li>
                       <li>  Arrays, Datatypes</li>
                       <li> Matrices</li>
                       <li> Broadcasting</li>
                       <li> Pandas Intro</li>
                       <li> series</li>
                       <li> Data Frames</li>
                       <li> Merge, Join, Concatenate</li>
                       <li> Handling missing data</li>
                       <li> IO Tools, Text, CSV, HDF5, JSON, EXCEL</li>
                       <li> Plotting</li>
                       <li> Matplotlib Intro</li>
                       <li> Line plot</li>
                       <li> Bar charts</li>
                       <li> Histograms</li>
                       <li> Scatter Plot</li>
                            </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="11">
                      <Accordion.Header><b> Debugging Python Programs</b></Accordion.Header>
                      <Accordion.Body style={{ textAlign: 'left' }}>
                        <ul style={{ paddingLeft: 0 }}>
                    
                       <li> Debug Python programs using pdb debugger</li>
                      <li>  Assert for debugging</li>
                      <li>  Testing with Python using UnitTest</li>
                      <li>  Iterable and generator in Python</li>
                      <li>  Yielding from the generators</li>
                       <li> Standard project setup in Python</li>
                            </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="12">
                      <Accordion.Header><b> Multithreading</b></Accordion.Header>
                      <Accordion.Body style={{ textAlign: 'left' }}>
                        <ul style={{ paddingLeft: 0 }}>
                    
                       <li> Multithreading with Python</li>
                       <li> What is multithreading?</li>
                       <li> Starting a New Thread</li>
                       <li> The Threading Module</li>
                       <li> Synchronizing Threads</li>
                            </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="13">
                      <Accordion.Header><b> Package Installation and Windows spreadsheet parsing</b></Accordion.Header>
                      <Accordion.Body style={{ textAlign: 'left' }}>
                        <ul style={{ paddingLeft: 0 }}>
                    
                       <li> What is pip, easy_install?</li>
                       <li> Set up the environment to install packages?</li>
                       <li> Install packages for XLS interface, Database Interface and Web interface</li>
                       <li> XML and XLS parsing with Python</li>
                        <li> Create XLS reports with Python</li>
                            </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="14">
                      <Accordion.Header><b> Django Syllabus</b></Accordion.Header>
                      <Accordion.Body style={{ textAlign: 'left' }}>
                        <ul style={{ paddingLeft: 0 }}>
                    
                       <li> Introduction to Django</li>
                       <li> Difference between a App and Project.</li>
                       <li> What are Migrations and Why we do that.</li>
                       <li> Admin part (How to create Superuser in python).</li>
                       <li> What are Views in Django URL Routing.</li>
                      <li>  What is Render and relative import.</li>
                       <li> A overview of settings file in Django.</li>
                       <li> How to configure Template.</li>
                      <li>  What are models.</li>
                       <li> Models are Admin Linkup.</li>
                       <li> Modelform creation.</li>
                       <li> Form Validation.</li>
                       <li> What is Context in Django.</li>
                       <li> Form in a View.</li>
                       <li> Custom Form.</li>
                       <li> How to setup Email in our Projects.</li>
                       <li> Static Files and Serving Static Files in Django.</li>
                       <li> Bootstrap Grid System.</li>
                       <li> Idea of CSS and Blocks.</li>
                       <li> URL names as Links.</li>
                       <li> How to add Authentication in Django Project with help of Registration Redux module.</li>
                       <li> Authentication Links in Navigation Bar.</li>
                       <li> Add Login Form in Bootstrap.</li>
                       <li> Query Set Basics.</li>
                       <li> How to render images and Videos on our site.</li>
                            </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  
                    </Accordion>
                </div>
            </Card.Body>
          </Card>
     
      </Row>
    </Container>
        {/* course objective end */}
            {/* question start */}
    <Row>
      <Col>
 
                    <h2 className='about-head mt-5'>Who can apply for the course?</h2>
                    <h5 className='text-start  ms-4'>The Python Programming course is open to a diverse range of individuals
               who are interested in learning Python programming. The course is designed to accommodate different 
              levels of experience, from beginners to more advanced learners.</h5>
                    <br></br>
                    <br></br>
                    <Accordion className='ms-4'>
              <Accordion.Item eventKey="0">
                <Accordion.Header><b>Beginner</b></Accordion.Header>
                <Accordion.Body style={{ textAlign: 'left' }}>
                  <ul style={{ paddingLeft: 0 }}>
                    <li>Individuals who have no prior programming experience and want to start their programming journey with Python.
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header><b>Student</b></Accordion.Header>
                <Accordion.Body style={{ textAlign: 'left' }}>
                  <ul style={{ paddingLeft: 0 }}>
                    <li>Students pursuing computer science or related fields who want to gain a solid foundation in Python programming.                                        </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header><b>Experience Python Devloper</b></Accordion.Header>
                <Accordion.Body style={{ textAlign: 'left' }}>
                  <ul style={{ paddingLeft: 0 }}>
                    <li>Programmers who are already familiar with Python but want to deepen their knowledge and explore advanced topics and best practices.
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header><b>Professional Software Engineer</b></Accordion.Header>
                <Accordion.Body style={{ textAlign: 'left' }}>
                  <ul style={{ paddingLeft: 0 }}>
                    <li>Individuals who have no prior programming experience and want to start their programming journey with Python.
                      Professionals working in the software development industry who want to add Python to their skillset or update their Python knowledge.                </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header><b>Enthusiasts And Self-Learner</b></Accordion.Header>
                <Accordion.Body style={{ textAlign: 'left' }}>
                  <ul style={{ paddingLeft: 0 }}>
                    <li>Individuals interested in programming and specifically Python, regardless of their professional background.</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>


            </Accordion>
             
      </Col>
      <Col>
      <div >
                    <img src="../image/girl1.png" height='500px' alt="Professionals" />
                </div>
      </Col>
    </Row>
        {/* question end */}
      
    </div>
  )
}
export default PythonPage;