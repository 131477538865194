import './admindashboard.css';
import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CgMenuLeftAlt } from "react-icons/cg";
import { MdDashboard } from "react-icons/md";
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { TbCategoryPlus } from "react-icons/tb";
import { BiSolidBookContent } from "react-icons/bi";
import { AiFillProduct } from "react-icons/ai";
import { IoCall } from "react-icons/io5";
import { RiContactsBook3Fill } from "react-icons/ri";
import { MdLogout } from "react-icons/md";
import { IoIosPeople } from "react-icons/io";
import { IoSchoolSharp } from "react-icons/io5";
import API_BASE_URL from '../Api';
import Swal from 'sweetalert2';

const AdminDashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [activeTab, setActiveTab] = useState('#dashboard');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [, setData] = useState("Loading...");
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleSelect = (selectedKey) => {
    setActiveTab(selectedKey);
    if (isMobile) {
      setSidebarOpen(false);
    }
  };

  const handleLogout = () => {
    Swal.fire({
      title: 'Are you sure you want to logout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, logout',
      cancelButtonText: 'No, stay logged in'
    }).then((result) => {
      if (result.isConfirmed) {
        // Remove the token from session storage
        sessionStorage.removeItem('token');
        
        // Navigate to the home page
        navigate('/');
      }
    });
  };
  
  const getData = () => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      console.error("No token found");
      setData("User not authenticated");
      navigate('/login');  // Redirect to login page if no token
      return;
    }

    fetch(`${API_BASE_URL}/api/user/getUser`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, 
      },
    })
      .then((response) => {
        if (response.status === 401) {  // If unauthorized, redirect to login
          throw new Error('Unauthorized');
        }
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data && data.message) {
          setData(data.message);
        } else {
          setData("No advice found.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setData("Error fetching data.");
        if (error.message === 'Unauthorized') {
          navigate('/login');  // Redirect to login if unauthorized
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setSidebarOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);

    if (isMobile) {
      setSidebarOpen(false);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  window.addEventListener("beforeunload", () => {
    getData();
    console.log("API call before page reload");
  });

  window.addEventListener("unload", () => {
    getData();
    console.log("API call after page reload");
  });

  return (
    <div className="dashboard">
      <Navbar variant="dark" expand="lg" className="navbar-custom">
        <Button variant="" className="menu-btn ms-2" onClick={toggleSidebar}>
          <CgMenuLeftAlt size={30} />
        </Button>
        <Navbar.Brand href="#home" className='ms-auto me-auto'>Admin Dashboard</Navbar.Brand>
      </Navbar>
      <div className={`sidebar ${sidebarOpen ? 'open' : 'collapsed'}`}>
        <Nav className="flex-column" activeKey={activeTab} onSelect={handleSelect}>
          <OverlayTrigger placement="right" overlay={<Tooltip>Dashboard</Tooltip>}>
            <Nav.Link className={`tabtext ${activeTab === '#dashboard' ? 'active' : ''}`} href="#dashboard" as={Link} to='dashboard'>
              <MdDashboard size={20} color='white' className="icon-dashboard" />
              {sidebarOpen && <span className="text">Dashboard</span>}
            </Nav.Link>
          </OverlayTrigger>
          <OverlayTrigger placement="right" overlay={<Tooltip>Product Information</Tooltip>}>
            <Nav.Link className={`tabtext ${activeTab === '#ProductInformation' ? 'active' : ''}`} href="#ProductInformation" as={Link} to='productinformation'>
              <MdOutlineProductionQuantityLimits size={20} color='white' className="icon-dashboard" />
              {sidebarOpen && <span className="text">Product Information</span>}
            </Nav.Link>
          </OverlayTrigger>
          <OverlayTrigger placement="right" overlay={<Tooltip>Product Category</Tooltip>}>
            <Nav.Link className={`tabtext ${activeTab === '#ProductCategory' ? 'active' : ''}`} href="#ProductCategory" as={Link} to='productcategory'>
              <TbCategoryPlus size={20} color='white' className="icon-dashboard" />
              {sidebarOpen && <span className="text">Product Category</span>}
            </Nav.Link>
          </OverlayTrigger>
          <OverlayTrigger placement="right" overlay={<Tooltip>Product SubCategory</Tooltip>}>
            <Nav.Link className={`tabtext ${activeTab === '#ProductSubCategory' ? 'active' : ''}`} href="#ProductSubCategory" as={Link} to='productsubcategory'>
              <AiFillProduct size={20} color='white' className="icon-dashboard" />
              {sidebarOpen && <span className="text">Product SubCategory</span>}
            </Nav.Link>
          </OverlayTrigger>
          <OverlayTrigger placement="right" overlay={<Tooltip>Product Content</Tooltip>}>
            <Nav.Link className={`tabtext ${activeTab === '#ProductContent' ? 'active' : ''}`} href="#ProductContent" as={Link} to='productcontent'>
              <BiSolidBookContent size={20} color='white' className="icon-dashboard" />
              {sidebarOpen && <span className="text">Product Content</span>}
            </Nav.Link>
          </OverlayTrigger>
          <OverlayTrigger placement="right" overlay={<Tooltip>Contact</Tooltip>}>
            <Nav.Link className={`tabtext ${activeTab === '#Contact' ? 'active' : ''}`} href="#Contact" as={Link} to='getcontact'>
              <RiContactsBook3Fill size={20} color='white' className="icon-dashboard" />
              {sidebarOpen && <span className="text">Contact</span>}
            </Nav.Link>
          </OverlayTrigger>
          <OverlayTrigger placement="right" overlay={<Tooltip>Enquiry</Tooltip>}>
            <Nav.Link className={`tabtext ${activeTab === '#Enquiry' ? 'active' : ''}`} href="#Enquiry" as={Link} to='getenquiry'>
              <IoCall size={20} color='white' className="icon-dashboard" />
              {sidebarOpen && <span className="text">Enquiry</span>}
            </Nav.Link>
          </OverlayTrigger>
          <OverlayTrigger placement="right" overlay={<Tooltip>Upcoming Batch</Tooltip>}>
            <Nav.Link className={`tabtext ${activeTab === '#Course' ? 'active' : ''}`} href="#Course" as={Link} to='upcomingbatchform'>
              <IoIosPeople size={20} color='white' className="icon-dashboard" />
              {sidebarOpen && <span className="text">Upcoming Batch</span>}
            </Nav.Link>
          </OverlayTrigger>
          <OverlayTrigger placement="right" overlay={<Tooltip>Add Institute</Tooltip>}>
            <Nav.Link className={`tabtext ${activeTab === '#Institute' ? 'active' : ''}`} href="#Institute" as={Link} to='addinstitutedetail'>
              <IoSchoolSharp size={20} color='white' className="icon-dashboard" />
              {sidebarOpen && <span className="text">Add Institute</span>}
            </Nav.Link>
          </OverlayTrigger>
          {/* Repeat similar blocks for other links */}
          <OverlayTrigger placement="right" overlay={<Tooltip>Logout</Tooltip>}>
            <Nav.Link className={`tabtext ${activeTab === '#Logout' ? 'active' : ''}`} onClick={handleLogout}>
              <MdLogout size={20} color='white' className="icon-dashboard" />
              {sidebarOpen && <span className="text">Logout</span>}
            </Nav.Link>
          </OverlayTrigger>
        </Nav>
      </div>
      <Container fluid className={`content ${sidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        <Outlet></Outlet>
      </Container>
    </div>
  );
};

export default AdminDashboard;
