import './navbar.css';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import API_BASE_URL from './Api';
import { Link } from 'react-router-dom';

const Navigation = () => {
  const navbarCollapseRef = useRef(null);
  const [headerData, setHeaderData] = useState([]);
  const [timeData, setTime] = useState('');
  const [mobileData, setMobile] = useState('');

  useEffect(() => {
    const fetchHeaderData = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await axios.get(`${API_BASE_URL}/company/getAllCompanyProfileDetails`);

        if (response.data) {
          console.log(response.data);
          setHeaderData(response.data); 
          setTime(response.data[0]?.time || '');
          setMobile(response.data[0]?.mobile || '');
        }
      } catch (error) {
        console.error('Error fetching header data:', error);
        alert('Unable to fetch header data.');
      }
    };

    fetchHeaderData();
  }, []);

  useEffect(() => {
    const handleNavLinkClick = () => {
      if (navbarCollapseRef.current && navbarCollapseRef.current.classList.contains('show')) {
        navbarCollapseRef.current.classList.remove('show');
      }
    };

    const navLinks = navbarCollapseRef.current.querySelectorAll('.nav-link, .dropdown-item');
    navLinks.forEach((link) => link.addEventListener('click', handleNavLinkClick));

    return () => {
      navLinks.forEach((link) => link.removeEventListener('click', handleNavLinkClick));
    };
  }, []);

  return (
    <div>
      {/* Topbar Start */}
      <div className="container-fluid topbar text-light p-0">
        <div className="row gx-0 d-none d-lg-flex">
          <div className="col-lg-7 px-5 text-start">
            {/* Address field removed as requested */}
            <div className="h-100 d-inline-flex align-items-center">
              <small className="icon far fa-clock text-primary me-2"></small>
              <small>{timeData}</small>
            </div>
          </div>
          <div className="col-lg-5 px-5 text-end">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <small className="icon fa fa-phone-alt text-primary me-2"></small>
              <small>
                <a href={`tel:${mobileData}`} className="text-light">
                  {mobileData}
                </a>
              </small>
            </div>
            <div className="h-100 d-inline-flex align-items-center mx-n2">
              <a
                className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary"
                href="https://www.facebook.com/yourprofile"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="btn-circle">
                  <i className="icon fab fa-facebook-f"></i>
                </span>
              </a>
              <a
                className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary"
                href="https://wa.me/yourphonenumber"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="btn-circle">
                  <i className="icon fab fa-whatsapp"></i>
                </span>
              </a>
              <a
                className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary"
                href="https://www.linkedin.com/in/yourprofile"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="btn-circle">
                  <i className="icon fab fa-linkedin-in"></i>
                </span>
              </a>
              <a
                className="btn btn-square btn-link rounded-0"
                href="https://www.instagram.com/yourprofile"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="btn-circle">
                  <i className="icon fab fa-instagram"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Topbar End */}

      {/* Navbar Start */}
      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
        <Link to="/" className="navbar-brand d-flex align-items-center border-end px-4 px-lg-5">
          <img src='./img/logo.png' width='170' height='60' alt="Logo" />
        </Link>
        <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse" ref={navbarCollapseRef}>
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <Link to="/" className="logotextchange nav-item nav-link active"><b>Home</b></Link>
            <Link to="/about" className="logotextchange nav-item nav-link"><b>About</b></Link>
            <Link to="/courses" className="logotextchange nav-item nav-link"><b>Courses</b></Link>
            <Link to="/placement" className="logotextchange nav-item nav-link"><b>Our Placement</b></Link>
            <Link to="/contact" className="logotextchange nav-item nav-link"><b>Contact</b></Link>
          </div>
          <Link to="/login" className="login-btn btn btn-primary nav-item nav-link py-4 px-lg-5 d-lg-block">
            <b>LOGIN</b><i className="fa fa-arrow-right ms-3"></i>
          </Link>
        </div>
      </nav>
      {/* Navbar End */}
    </div>
  );
};

export default Navigation;

