import './JavaPage.css';
import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Card, Row,Col,Accordion } from 'react-bootstrap';
import { useState } from 'react';
import { BiPlusMedical } from "react-icons/bi";
import { FaCertificate, FaUserGraduate, FaBriefcase, FaProjectDiagram, FaChalkboardTeacher, FaHandshake, FaTasks, FaClock, FaStar } from 'react-icons/fa';
const JavaPage = () => {

  const [, setClicked] = useState(false);

  const handleScrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setClicked(true); 
    };

  return (
    <div>
      {/* page header start*/}
       <div className="container-fluid page-header py-6 my-6 mt-0 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center">
            <h1 className="display-5 text-white animated slideInDown mt-4 mb-4">JAVA TRAINING INSTITUTE IN PUNE</h1>
            <h5 className='marquee text-white'>
            <div>NOTE: Enrollment in the Fullstack Java course  (you’ll also get the Fullstack MERN course for free!)</div>
        </h5>
            
      </div>
      </div>
      {/* page header end */}
      {/* course overview start */}
            <div className="course-info mt-0">
                <h2 className='about-head'>Full Stack Java Course Overview</h2>
                <BiPlusMedical size={30}/>
                <h2 className="  head1 animated slideInDown mt-2 mb-4 text-decoration-underline">Full Stack "MERN" (Free)</h2>
                <h5>NOTE : Enrollment in the Fullstack Java course ( you’ll also get the Fullstack MERN course for free!)</h5>
                <Container fluid className="display-text-container">
   
          <p className='course-overview'>
            The Comprehensive Java Programming course is designed to cater to both beginners and experienced developers looking to enhance their Java programming skills. The course covers the fundamental concepts of Java programming and gradually progresses to more advanced topics, ensuring a solid understanding of the language for all participants. Whether you are a beginner with no prior coding experience or an experienced developer seeking to expand your knowledge, this course will provide you with the necessary skills to develop robust Java applications.
          </p>
     
    </Container>
                <br></br>
                <div className="enroll-now">
          <Link to='/enquiry'>
            <button className="enroll-now-button" onClick={handleScrollToTop}>Enroll Now</button>
          </Link>
          </div>
          </div>
          {/* course overview end */}
          {/* key highlights start */}
          <div className="key-highlights">
                <h2 className='head1'> <b>Key Highlights</b></h2>
                <div className="highlight-grid">
                    <div className="highlight-item">
                        <FaCertificate size={80} />
                        <p>Certification</p>
                    </div>
                    <div className="highlight-item">
                        <FaUserGraduate size={80} />
                        <p>Mentor Support</p>
                    </div>
                    <div className="highlight-item">
                        <FaBriefcase size={80} />
                        <p>100% Job Assistance</p>
                    </div>
                    <div className="highlight-item">
                        <FaProjectDiagram size={80} />
                        <p>Projects and Assignments</p>
                    </div>
                    <div className="highlight-item">
                        <FaChalkboardTeacher size={80} />
                        <p>Interview Preparation</p>
                    </div>
                    <div className="highlight-item">
                        <FaHandshake size={80} />
                        <p>Hands-on Experience</p>
                    </div>
                    <div className="highlight-item">
                        <FaTasks size={80} />
                        <p>Comprehensive Coverage</p>
                    </div>
                    <div className="highlight-item">
                        <FaClock size={80} />
                        <p>Flexible Learning Options</p>
                    </div>
                </div>
            </div>
              {/* key highlights end */}
                {/* learn java  start */}
            <Container fluid className="display-text-container">
       <Card className="display-text-card">
        <Card.Body> 
            <div className="java-card">
                <h2 className='about-head'><b>Learn Java</b></h2>
                <p>Java is a high-level, object-oriented programming language used to create standalone and web-based programs. It is platform-independent and architecturally neutral, making it one of the most widely used programming languages in the industry.</p>
                <div className="rating">
                    <p>5.0</p>
                    <div className="stars">
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                    </div>
                    <p>332 reviews</p>
                </div>
                <div className="enroll-now">
                    <Link to='/enquiry'>
                    <button className="enroll-now-button" onClick={handleScrollToTop}>Enquiry Now</button>
                    </Link>
                   
                </div>
            </div>
          </Card.Body>
          </Card>
          </Container>
            {/* learn java end */}
            {/* java course overview start */}
            <div className="container-xxl py-6">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                    {/* <h6 className="text-primary text-uppercase mb-2">Why Choose Us!</h6> */}
                    <h1 className="head1 display-6 mb-4">Java Course Overview</h1>
                    <p className="course-overview">
                        At our Java Training Classes in Pune, we focus on providing comprehensive training to improve one’s capability, capacity, and performance in Java programming. Our courses cover a range of topics, including ByteCode, JVM architecture, classpath, objects, and Java syntax synchronization techniques. Our Java technical course is suitable for beginners and professionals alike, and it provides hands-on experience with Classes and objects, Object-Oriented Programming (OOP), and Modifiers. Acquiring knowledge of Java can lead to a career as a Java developer, with numerous frameworks available such as Hibernate, Spring, Web services, and more.
                        In terms of opportunities and advancement, Java is the best programming language. It is widely used in various technologies like Hadoop, SalesForce, Cloud Computing, and Android. At Vtech Institute, we have the expertise and knowledge to help our students master these skill sets and advance in all Java-related environments.
                    </p>
                    {/* <p className="mb-5">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore erat amet</p> */}
                    <div className="row gy-5 gx-4">
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0 text-start">Java programming fundamentals</h5>
                            </div>
                         
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.2s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">Application development</h5>
                            </div>
                           
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">Java libraries and APIs</h5>
                            </div>
                          
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">Database integration</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">Web development</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">Java frameworks</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">Testing and debugging</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0 text-start">Object-oriented design principles</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0 text-start">Error handling and exception handling</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">Performance optimization</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0 blink">Live Industry Projects Working</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0 blink">Internship(Free)</h5>
                            </div>
                       
                        </div>
                    </div>
                    
                </div>
                </div>
                </div>
                </div>     
                   {/* java course overview end */}
                      {/* course objective start */}
     <Container fluid className="course-objectives-container">
      <div className='course-objective'>
      <h2 className='about-head'>Course Objectives</h2>
      </div>
   

      <Row className="justify-content-center mt-4">
       
          <Card className="basic-java-card shadow" style={{ width: '70rem' }}>
            <Card.Body>
              <h1 className='head1'>BASIC OF JAVA</h1>
              <hr />
              <h4>Course Duration: 90 HOURS</h4>
              <p>Core Java Syllabus - Object-oriented programming (OOPs concepts) in Java</p>
              <img src='./image1/java.png' alt='Java Logo' width='50px' />
              <div className="skills-list">
                                <Accordion>
                                <Accordion.Item eventKey="0">
                                        <Accordion.Header><b>Core Java - Java Language, OOPS, Programming</b></Accordion.Header>
                                        <Accordion.Body style={{ textAlign: 'left' }}>
                                            <ul style={{ paddingLeft: 10 }}>
                                          <li>  Basics of Java</li>
                                            <li>Input/Output in Java</li>
                                           <li> Flow Control in Java</li>
                                           <li> Operators in Java</li>
                                           <li> Arrays in Java</li>
                                           <li> Strings in Java</li>
                                           <li> OOPS in Java</li>
                                           <li> Classes of Java</li>
                                           <li> Interfaces in Java</li>
                                           <li> Methods in Java</li>
                                           <li> Packages in Java</li>
                                           <li> Collection Framework in Java</li>
                                           <li> Collection Classes in Java</li>
                                           <li> Memory Allocation in Java</li>
                                           <li> Exception Handling in Java</li>
                                           <li> Multithreading in Java</li>
                                           <li> Synchronization in Java</li>
                                           <li> File Handling in Java</li>
                                           <li> Java Regex</li>
                                           <li> Java IO</li>
                                           <li> Java Networking</li>
                                           <li> Java SE 8 </li>
                                           <li> Java Date & Time</li>
                                           <li> Java JDBC</li>
                                    </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header><b>Java API and Project</b></Accordion.Header>
                                        <Accordion.Body style={{ textAlign: 'left' }}>
                                            <ul style={{ paddingLeft: 10 }}>
                                            <li>API and API Documentation</li>
                                             <li>Fundamental Classes – Object, Class, System, Runtime</li>
                                             <li>String Handling</li>
                                             <li>Exception Handling and Assertions</li>
                                             <li>Multithreading with JVM Architecture</li>
                                             <li>IO Streams (File IO)</li>
                                             <li>Networking (Socket Programming)</li>
                                             <li>Wrapper Classes with Auto boxing and unboxing</li>
                                             <li>Collections with Generics</li>
                                             <li>Java 5, 6, 7, 8 new features</li>
                                             <li>Inner classes</li>
                                             <li>AWT, Swings, Applet</li>
                                             <li>Regular Expressions</li>
                                             <li>Formatting date, time (java.text package)</li>
                                    </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    

                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header><b>Java 8 Features</b></Accordion.Header>
                                        <Accordion.Body style={{ textAlign: 'left' }}>
                                            <ul style={{ paddingLeft: 10 }}>
                                          <li>  Lambda Expressions</li>
                                             <li> Functional Interfaces</li>
                                             <li> Method Reference</li>
                                             <li> Streams</li>
                                             <li> Comparable and Comparator</li>
                                             <li> Optional Class</li>
                                             <li> Date/Time API</li>
                                    </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header><b>Advance Java </b></Accordion.Header>
                                        <Accordion.Body style={{ textAlign: 'left' }}>
                                            <ol style={{ paddingLeft: 10 }}>
                                            <li>1 About Advanced Java Training</li>
                                             <li>2 Advanced Java Training Course Prerequisite</li>
                                             <li>3 Advanced Java Training Course Objective</li>
                                             <li>4 Advanced Java Training Course Duration</li>
                                             <li>5 Advanced Java Training Course Content Overview</li>
                                             <li>6 JDBC</li>
                                             <li>6.1 JDBC API and JDBC Drivers</li>
                                             <li>6.2 JDBC API</li>
                                             <li>6.3 SQL</li>
                                             <li>6.4 Establishing Connection to Database</li>
                                             <li>6.5 Statement interface</li>
                                             <li>6.6 PreparedStatement interface</li>
                                             <li>6.7 Resultset Interface</li>
                                             <li>6.8 SQL 99 Datatypes</li>
                                             <li>6.9 Metadata</li>
                                             <li>6.10 CallableStatement</li>
                                             <li>6.11 Batch updates</li>
                                             <li>6.12 Transaction Management</li>
                                             <li>6.13 Connection Pooling</li>
                                             <li>6.14 RowSet</li>
                                             <li>6.15 Communicating with difference databases</li>
                                             <li>6.16 JDBC 4.0 Features</li>
                                             <li>6.17  Working With Properties File</li>
                                             <li>7 SERVLET</li>
                                             <li>7.1 JEE</li>
                                             <li>7.2 Introduction</li>
                                             <li>7.3 Servlet API</li>
                                             <li>7.4 HTTP Protocol</li>
                                             <li>7.5 Developing Servlet</li>
                                             <li>7.6 Developing And Deploying Servlet Apps in Diff Servers Like</li>
                                             <li>7.7 ServletRequest</li>
                                             <li>7.8 ServletResponse</li>
                                             <li>7.9 ServletConfig</li>
                                             <li>7.10 GenericServlet</li>
                                             <li>7.11 Working with welcome-file</li>
                                             <li>7.12 Methods of loading Servlet</li>
                                             <li>7.13 ServletContext</li>
                                             <li>7.14 HttpServlet</li>
                                             <li>7.15 HttpServletRequest</li>
                                             <li>7.16 HttpServletResponse</li>
                                             <li>7.17 RequestDispatching</li>
                                             <li>7.18 Redirecting</li>
                                             <li>7.19 Attributes</li>
                                             <li>7.20 State and Session Management</li>
                                             <li>7.21 URL Rewriting</li>
                                             <li>7.22 Hidden form fields</li>
                                             <li>7.23 Cookies</li>
                                             <li>7.24 HttpSession</li>
                                             <li>7.25 Error Handling in Servlet</li>
                                             <li>7.26 Filters</li>
                                             <li>7.27 Listeners</li>
                                             <li>7.28 Authentication and Authorization(Security In Servlet Programming)</li>
                                             <li>7.29 Working Connection Pooling</li>
                                             <li>7.30 Working with domains</li>
                                             <li>7.31 Working with Servlet 2.5/3.0/3.1 features</li>
                                             <li>7.32 Annotations in Servlet Programming</li>
                                             <li>8 JSP</li>
                                             <li>8.1 Introduction</li>
                                             <li>8.2 JSP Basics</li>
                                             <li>8.3 JSP Tags</li>
                                             <li>8.4 Scripting Elements</li>
                                             <li>8.5 JSP implicit object</li>
                                             <li>8.6 Directives</li>
                                             <li>8.7 Standard Actions</li>
                                             <li>8.8 Java Bean</li>
                                             <li>8.9 Using Java Bean in JSP</li>
                                             <li>8.10 Expression Language</li>
                                             <li>8.11 Working JSTL</li>
                                             <li>8.12 Custom Tags</li>
                                             <li>8.13 MVC Architecture</li>
                                             <li>8.14 Developing Project using MVC</li>
                                            </ol>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header><b>Spring</b></Accordion.Header>
                                        <Accordion.Body style={{ textAlign: 'left' }}>
                                            <ol style={{ paddingLeft: 10 }}>
                                         <li>   Basics of Spring Framework</li>
                                             <li> Software Setup and Configuration (STS/Eclipse/IntelliJ)</li>
                                             <li> Core Spring</li>
                                             <li> Spring Annotations</li>
                                            <li>  Spring Boot</li>
                                             <li> Spring MVC</li>
                                             <li> Spring with REST API</li>
                                             <li> Spring Data JPA</li>
                                             <li> Spring JDBC</li>
                                             <li> Spring ORM or Spring Hibernate</li>
                                             <li> Spring AOP</li>
                                             <li> Spring Security</li>
                                             <li> Interview Questions on Spring Framework</li>
                                             <li> Advantages of Spring</li>
                                             <li> Spring Latest & Upcoming Features</li>
                                    </ol>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header><b> Spring MVC</b></Accordion.Header>
                                        <Accordion.Body style={{ textAlign: 'left' }}>
                                            <ol style={{ paddingLeft: 10 }}>
                                          <li>  What is Spring MVC?</li>
                                             <li>Spring Model-View-Controller</li>
                                           <li>  Spring MVC – Basics</li>
                                            <li> Spring MVC – Software Setup and Configuration (STS/Eclipse)</li>
                                            <li> Prerequisite (Spring Core Concepts)</li>
                                            <li> Core Spring MVC</li>
                                            <li> Spring MVC – Annotation</li>
                                            <li> Spring MVC – Form Handling</li>
                                            <li> Spring MVC with JSTL</li>
                                            <li> Spring MVC with REST API</li>
                                            <li> Spring MVC with Database</li>
                                            <li> Advantages of Spring MVC Framework</li>
                                         </ol>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header><b>Spring Boot </b></Accordion.Header>
                                        <Accordion.Body style={{ textAlign: 'left' }}>
                                            <ol style={{ paddingLeft: 10 }}>
                                            <li>Spring Boot – Basics</li>
                                             <li>Spring Boot – Software Setup and Configuration (STS/Eclipse/IntelliJ)</li>
                                             <li>Prerequisite (Spring Core Concepts)</li>
                                             <li>Spring Boot Core</li>
                                             <li>Spring Boot with REST API</li>
                                             <li>Spring Boot with Database and Data JPA</li>
                                             <li>Spring Boot with Microservices</li>
                                             <li>Spring Boot with Kafka</li>
                                             <li>Spring Boot with AOP</li>
                                             <li>Spring Boot Testing</li>
                                             <li>What You Can Do with Spring Boot</li>
                                             <li>Spring Boot Features</li>
                                             <li>Spring Boot Advantages</li>
                                             <li>Limitations of Spring Boot</li>
                                    </ol>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                  
                                    <Accordion.Item eventKey="7">
                                        <Accordion.Header><b> Hibernate</b></Accordion.Header>
                                        <Accordion.Body style={{ textAlign: 'left' }}>
                                            <ol style={{ paddingLeft: 10 }}>
                                          <li>  Hibernate Basics</li>
                                           <li>  Core Hibernate</li>
                                            <li> Hibernate Mapping</li>
                                          <li>   Hibernate Annotations</li>
                                            <li> Hibernate with Spring Framework</li>
                                            <li> Hibernate with Database</li>
                                            <li> Hibernate Log4j</li>
                                            <li> Inheritance Mapping</li>
                                            <li> Hibernate Advantages</li>
                                            <li> Hibernate Supported Databases</li>
                                                                                </ol>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                
                                    <Accordion.Item eventKey="8">
                                        <Accordion.Header><b>Web Services </b></Accordion.Header>
                                        <Accordion.Body style={{ textAlign: 'left' }}>
                                            <ol style={{ paddingLeft: 10 }}>
                                           <li> About Web Services</li>

                                        
                                             <li>SOA Architecture Principles</li>
                                             <li>Types of web services</li>
                                             <li>About REST Web Service</li>

                                         
                                             <li>Rest principles & Terminology</li>
                                            <li> About JAX-RS API</li>
                                             <li>Annotation Inheritance with REST</li>
                                             <li>REST Client Design Scenarios</li>
                                             <li>REST Service Endpoint & URL Design Scenarios</li>
                                            <li> Realtime Examples with scenarios</li>
                                            <li> About SOAP Web Service</li>

                                       
                                            <li> Anatomy of SOAP</li>
                                            <li> Structure of SOAP</li>
                                            <li> SOAP with HTTP</li>
                                            <li> SOAP-1.2 Features</li>
                                            <li> About SAAJ</li>
 
                                         
                                           <li>  Creating a SOAP Message</li>
                                           <li>  Retrieving SOAP Message</li>
                                           <li>  Sending SOAP Message</li>
                                            <li> Adding attachments to SOAP Message</li>
                                           <li>  About WSDL</li>


                                           <li> WSDL Structure (Covers 2.0)</li>
                                            <li> Message Exchanging Design Patterns & Modes</li>
                                            <li>About JAX-WS API</li>

                                           <li> Fundamentals of JAX-WS</li>
                                            <li>Web Service meta data annotations</li>
                                            <li> XML Generation using JAXB Annotations</li>
                                           <li>  JAX-WS Annotations</li>
                                            <li> Realtime Examples with scenarios</li>
                                           <li>  About Web services Security</li>

                                          
                                           <li> Need of security</li>
                                           <li> Types of securities</li>
                                           <li>  Real time Examples with scenarios</li>
                                           <li> About Apache-CXF</li>

                                       
                                          <li>  High level architecture</li>
                                          <li> Real time Examples with scenarios</li>
                                           <li> About Apache-AXIS</li>

                                        
                                          <li>   High level architecture</li>
                                           <li> Realtime Examples with scenarios</li>
                                          <li>   Course Highlights: </li>

                                            <li> All examples are covered with real time scenarios</li>
                                            <li> Covers development with Eclipse & NetBeans IDE’s</li>
                                            <li> Web services testing and monitoring tools</li>
                                    </ol>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    </Accordion>
                            </div>
            </Card.Body>
          </Card>
    
      </Row>
    </Container>
        {/* course objective end */}
            {/* question start */}
    <Row>
      <Col>
 
                    <h2 className='mt-5 about-head'>Who can apply for the course?</h2>
                    <br></br>
                    <br></br>
                    <Accordion className='ms-4'>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><b>Beginner</b></Accordion.Header>
                            <Accordion.Body style={{ textAlign: 'left' }}>
                                <ul style={{ paddingLeft: '50px' }}>
                                    <li>Individuals who have no prior programming experience and want to start their programming journey with Java.
                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><b>Student</b></Accordion.Header>
                            <Accordion.Body style={{ textAlign: 'left' }}>
                                <ul style={{ paddingLeft: '20px' }}>
                                    <li>Students pursuing computer science or related fields who want to gain a solid foundation in Java programming.                                        </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><b>Experience Java Devloper</b></Accordion.Header>
                            <Accordion.Body style={{ textAlign: 'left' }}>
                                <ul style={{ paddingLeft:'20px'}}>
                                    <li>Programmers who are already familiar with Java but want to deepen their knowledge and explore advanced topics and best practices.
                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><b>Professional Software Engineer</b></Accordion.Header>
                            <Accordion.Body style={{ textAlign: 'left' }}>
                                <ul style={{ paddingLeft: '20px' }}>
                                    <li>Individuals who have no prior programming experience and want to start their programming journey with Java.
                                        Professionals working in the software development industry who want to add Java to their skillset or update their Java knowledge.                </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header><b>Enthusiasts And Self-Learner</b></Accordion.Header>
                            <Accordion.Body style={{ textAlign: 'left' }}>
                                <ul style={{ paddingLeft: '20px'}}>
                                    <li>Individuals interested in programming and specifically Java, regardless of their professional background.</li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>


                    </Accordion>
             
      </Col>
      <Col>
      <div >
                    <img src="../image/girl1.png" height='500px' alt="Professionals" />
                </div>
      </Col>
    </Row>
        {/* question end */}
        <Container className='justify-content-center '>
    <h3 className='mt-3 mb-5 about-head'>Frequently Asked Questions</h3>
    <Accordion className="mt-3">
                {/* Core Java Accordion Items */}
                <Accordion.Item eventKey="0">
                  <Accordion.Header>1. Does Vtech  Academy offer placement assistance after course completion?</Accordion.Header>
                  <Accordion.Body>
                    Yes, Vtech  Academy offers placement assistance to help students secure jobs after course completion.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>2. Who are the trainers for the Java basic to advance course?</Accordion.Header>
                  <Accordion.Body>
                    The trainers are experienced and certified Java professionals with extensive industry knowledge.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>3. Will I be given a certificate once the course is over?</Accordion.Header>
                  <Accordion.Body>
                    Yes, you will receive a certificate upon successful completion of the course.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>4. What job opportunities are available for Java developers?</Accordion.Header>
                  <Accordion.Body>
                    Java developers can work in various roles such as software developer, web developer, and application developer in numerous industries.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>5. How much can I expect to earn as a Java developer?</Accordion.Header>
                  <Accordion.Body>
                    The salary of a Java developer varies based on experience, location, and company, but it is generally competitive in the IT industry.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>6. Where can I learn Core Java and Advance Java?</Accordion.Header>
                  <Accordion.Body>
                    You can learn both Core Java and Advanced Java at Vtech  Academy in Pune, where we offer comprehensive training programs.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>7. How do I enroll in a course at Vtech  Academy?</Accordion.Header>
                  <Accordion.Body>
                    You can enroll by visiting our website or contacting us directly at our Pune office.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>8. Why should I learn Java from Vtech  Academy?</Accordion.Header>
                  <Accordion.Body>
                    VTECH  Academy is the best Java training institute in Pune. We offer the best IT training programs in both Core Java and Advanced Java. Our experienced and certified Java professionals use a mix of theoretical lectures and hands-on exercises to ensure that our students gain the skills and knowledge they need to succeed as Java developers in today's competitive job market.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
        </Container>

    </div>
  )
}
export default JavaPage;