import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Footer from './component/Footer';
import Home from './component/Home';
import Navigation from './component/Navigation';
import AboutUs from './component/AboutUs';
import Courses from './component/Courses';
import ContactUs from './component/ContactUs';
import EnquiryForm from './component/EnquiryForm';
import JavaPage from './component/Courses/JavaPage';
import PythonPage from './component/Courses/PythonPage';
import MernStack from './component/Courses/MernStack';
import Login from './component/Login';
import AdminDashboard from './component/Dashboard/AdminDashboard';
import ProductInformation from './component/Dashboard/ProductInformation';
import ProductContent from './component/Dashboard/ProductContent';
import Placement from './component/Placement';
import ProductCategory from './component/Dashboard/ProductCategory';
import ProductSubCategory from './component/Dashboard/ProductSubCategory';
import GetContact from './component/GetContact';
import GetEnquiry from './component/GetEnquiry';
import UpcomingBatchForm from './component/Dashboard/UpcomingBatchForm';
import AddInstituteDetail from './component/Dashboard/AddInstituteDetail';


function App() {
  return (
    <div className="App">
      <Router>
        <MainContent />
      </Router>
    </div>
  );
}

function MainContent() {
  const location = useLocation();
  const isDashboard = location.pathname.startsWith('/dashboard');

  return (
    <>
      {/* {!isDashboard && <NewContact />} */}
      {!isDashboard && <Navigation/>}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/placement" element={<Placement/>} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/login" element={<Login />} />
        <Route path="/java" element={<JavaPage />} />
        <Route path="/python" element={<PythonPage />} />
        <Route path="/mern" element={<MernStack />} />
        <Route path="/enquiry" element={<EnquiryForm />} />
       {/* /dashboard navigation */}
        <Route path="/dashboard/*" element={<AdminDashboard></AdminDashboard>}>
          <Route index />
          <Route path="productinformation" element={<ProductInformation></ProductInformation>} />
          <Route path="productcategory" element={<ProductCategory></ProductCategory>} />
          <Route path="productsubcategory" element={<ProductSubCategory></ProductSubCategory>} />
          <Route path="productcontent" element={<ProductContent></ProductContent>} />
          <Route path="getcontact" element={<GetContact></GetContact>} />
          <Route path="getenquiry" element={<GetEnquiry></GetEnquiry>} />
          <Route path="upcomingbatchform" element={<UpcomingBatchForm></UpcomingBatchForm>} />
          <Route path="addinstitutedetail" element={<AddInstituteDetail></AddInstituteDetail>} />
       
        </Route>
      </Routes>
      {!isDashboard && <Footer/>}
    </>
  );
}

export default App;
