import React, { useState } from 'react';
import { Form, Button, Container, Card } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './ProductForm.css';

const ProductContent = () => {
  const [formData, setFormData] = useState({
    product: '',
    description: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData({
      ...formData,
      description: data
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);
  };

  return (
    <Container className="product-form-container">
      <Card className="product-form-card">
        <Card.Body>
          <Card.Title>Product Content</Card.Title>
          <Form onSubmit={handleSubmit}>

            <Form.Group controlId="formproduct">
              <Form.Label className="form-label">Select Product</Form.Label>
              <Form.Select
                name="product"
                value={formData.product}
                onChange={handleChange}
              >
                <option value="">Select Product</option>
                <option value="online">Java</option>
                <option value="offline">Python</option>
                <option value="offline">MERN</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formproduct">
              <Form.Label className="form-label">Product Category</Form.Label>
              <Form.Select
                name="product"
                value={formData.product}
                onChange={handleChange}
              >
                <option value="">Select Product category</option>
                <option value="online">Core Java</option>
                <option value="offline">Advance Java</option>
           
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formproduct">
              <Form.Label className="form-label">Product SubCategory</Form.Label>
              <Form.Select
                name="product"
                value={formData.product}
                onChange={handleChange}
              >
               <option value="">Select Product Sub category</option>
                <option value="online">JSP</option>
                <option value="offline">Servlet</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label className="form-label"></Form.Label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.description}
                onChange={handleEditorChange}
                config={{
                  height: 550// set the height here
                }}

              />
            </Form.Group>

            <div className='mt-4'>
            <Button variant="primary" className='submit-btn' type="submit">
              Submit
            </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ProductContent;
