import './home.css';
import React from 'react';
import Button from 'react-bootstrap/Button';
import { Table, Container } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import API_BASE_URL from './Api'; 
import axios from 'axios';


const Home = () => {
    const [upcomingBatches, setUpcomingBatches] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null); 
    const [, setClicked] = useState(false);
    const [expandedCards, setExpandedCards] = useState({});
  
    useEffect(() => {
      const fetchUpcomingBatches = async () => {
        try {
          const response = await axios.get(`${API_BASE_URL}/auth/getAllUpcomingBatches`);
          setUpcomingBatches(response.data);
        } catch (error) {
          setError(error.message || 'Something went wrong');
        } finally {
          setLoading(false);
        }
      };
  
      fetchUpcomingBatches();
    }, []);
  


    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setClicked(true); // Set clicked to true to trigger re-rendering
      };
      const handleReadMoreToggle = (cardId) => {
        setExpandedCards((prevExpandedCards) => ({
          ...prevExpandedCards,
          [cardId]: !prevExpandedCards[cardId]
        }));
      };
    
      const coursesData = [
        {
          id: 1,
          title: "Full Stack Java + Full Stack MERN(Free)",
          imgSrc: "image/java.png",
          description: [
            "Back-End: Core Java, Advance Java, JSP, Servlet, Hibernate, Spring, SpringBoot, MicroServices RESTful APIs, SQL/NoSQL databases",
            "Front-End: HTML, CSS, JavaScript, React/Angular/Vue",
            "Tools: Git, Eclipse, STS, Maven/Gradle, CI/CD (Jenkins)"
          ],
          shortDescription: [
            "Back-End: Core Java, Advance Java, JSP, Servlet, Hibernate..."
          ],
          link: "/java"
        },
        {
          id: 2,
          title: "Full Stack Python + Full Stack MERN(Free)",
          imgSrc: "image/python.png",
          description: [
            "Back-End: Core Python, Advance Python, Django/Flask, SQLAlchemy, RESTful APIs, SQL/NoSQL databases",
            "Front-End: HTML, CSS, JavaScript, React/Angular/Vue",
            "Tools: Git, pip/conda",
            "Machine Learning Libraries: Scikit-Learn, TensorFlow, PyTorch, Keras",
            "Data Science Libraries: Pandas, NumPy, SciPy, Matplotlib, Seaborn, Plotly"
          ],
          shortDescription: [
            "Back-End: Core Python, Advance Python, Django/Flask, SQLAlchemy..."
          ],
          link: "/python"
        },
        {
          id: 3,
          title: "Full Stack MERN",
          imgSrc: "image/MernStack.png",
          description: [
            "Back-End: Node.js, Express.js, MongoDB, RESTful APIs",
            "Front-End: React, HTML, CSS, JavaScript",
            "Tools: Git, npm/yarn, CI/CD (Jenkins)",
            "Skills: Testing (Jest), security best practices, problem-solving, team collaboration"
          ],
          shortDescription: [
            "Back-End: Node.js, Express.js, MongoDB, RESTful APIs..."
          ],
          link: "/mern"
        }
      ];
  return (
    <div>
         {/* <!-- Carousel Start --> */}
    <div className="container-fluid p-0 wow fadeIn" data-wow-delay="0.1s">
        <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="w-100 img-fluid" src="img/bg2.jpg" alt="carousel"/>
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-7">
                                    <h5 className="display-6 text-light mb-4 animated slideInDown">Best IT Training & Placement Institute in Pune</h5>
                                    <p className='mb-4'>Comprehensive Training Programs and 100% Placement Assistance for Success</p>
                                    <Link to="/enquiry" className="enquiry-btn btn  py-sm-3 px-sm-5">Enquire Now</Link>
                                    <Link to="/courses" className="btn btn-light py-sm-3 px-sm-5 ms-3">Our Courses</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="w-100 " src="img/bg2.jpg"  alt="carousel"/>
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-7">
                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    </div>
    {/* <!-- Carousel End --> */}

    {/* <!-- About Start --> */}
    <div className="container-xxl py-6">
        <div className="container-fluid">
            <div className="row g-5">
                <div className="col-lg-6  wow fadeInUp" data-wow-delay="0.1s">
                    <div className=" ps-5 pt-5 h-100" style={{"min-height": "400px"}}>
                        <img className=" w-100 h-100" src="image/studentquations.svg" alt=""/>
                        {/* <img className="position-absolute top-0 start-0 bg-white pe-3 pb-3" src="image/whychooseus.jpg" alt="" style={{width: "200px", height: "200px"}}/> */}
                    </div>
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="h-100">
                        <h6 className=" about-head text-uppercase mb-2">About Us</h6>
                        <h1 className="head1 display-6 mb-4">WHO WE ARE</h1>
                        <h5>VTECH Academy Is All About Excellence</h5>
                        <p className='text-start'>We provide you all the programming language training and Job Oriented Placment Programs.VTech Academy is the best IT training institute in Pune. We provide cutting-edge training programs for individuals and organizations looking to expand their knowledge and abilities in the area of IT and software development. In order to satisfy the constantly changing needs of the industry, our institute offers a wide choice of courses that are taught by professionals with in-depth knowledge of their respective industries. We have best experienced team & visiting industry expert trainers. We serve online and classroom IT training in Pune.</p>
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- About End --> */}


    {/* <!-- Courses Start --> */}
    <div className="container-xxl courses my-6 py-6 pb-0">
    <h5 className=' marquee text-dark'>
            <div>NOTE: Enrollment in the Fullstack Java course OR Fullstack Python course (you’ll also get the Fullstack MERN course for free!)</div>
        </h5>
        <div className="container">
          <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "500px" }}>
            <h6 className="text-primary text-uppercase mb-2">Our Courses</h6>
          </div>
          <div className="row g-4 justify-content-center">
            {coursesData.map(course => (
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s" key={course.id}>
                <div className="courses-item d-flex flex-column bg-white overflow-hidden">
                  <div className="position-relative mt-auto">
                    <img className="img-fluid" src={course.imgSrc} alt="" width='80px' />
                    <div className="courses-overlay">
                      <Link className="btn btn-outline-primary border-2" to={course.link} onClick={handleScrollToTop}>Read More</Link>
                    </div>
                  </div>
                  <div className="text-center p-4 pt-0">
                    <h6>{course.title}</h6>
                    <p>Certification</p>
                    <ol className="breadcrumb justify-content-center mb-0">
                      <li className="breadcrumb-item small"><i className="fa fa-signal text-primary me-2"></i>Beginner</li>
                      <li className="breadcrumb-item small"><i className="fa fa-calendar-alt text-primary me-2"></i>3 Week</li>
                    </ol>
                    <p><b>Description</b></p>
                    <ul className='text-start'>
                      {(expandedCards[course.id] ? course.description : course.shortDescription).map((desc, index) => (
                        <li key={index}>{desc}</li>
                      ))}
                    </ul>
                    <Button variant="link" onClick={() => handleReadMoreToggle(course.id)}>
                      {expandedCards[course.id] ? "Read Less" : "Read More"}
                    </Button>
                  </div>
                  <div className='text-center mt-2'>
                    <Button className='mt-2 mb-4 booknow' size="sm" as={Link} to={course.link} onClick={handleScrollToTop} style={{ border: 'none' }}>Book Now</Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

    {/* <!-- Courses End --> */}

{/* Upcoming batches start */}
<Container>
      <div className="d-flex justify-content-center text-center mt-1">
        <h3>
          <span className='logocolor1'>Up Coming :</span>
          <span className='logocolor2'>Batches</span>
        </h3>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Course Name</th>
              <th>Duration</th>
              <th>Date</th>
              <th>Time</th>
              <th>Mode Of Training</th>
              <th>Enroll</th>
            </tr>
          </thead>
          <tbody>
            {upcomingBatches.length > 0 ? (
              upcomingBatches.map((item, index) => (
                <tr key={index}>
                  <td>{item.courseName}</td>
                  <td>{item.duration}</td>
                  <td>{item.date}</td>
                  <td>{item.time}</td>
                  <td>{item.modeOfTraining}</td>
                  <td>
                    <div className='text-center mt-2'>
                      <Button
                        className='logobgcolor2'
                        href={item.enroll}
                        style={{ border: 'none' }}
                        size="sm"
                      >
                        Book Now
                      </Button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No upcoming batches available.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </Container>

{/* upcoming batches end */}
    {/* <!-- Features Start --> */}
    <div className="container-xxl py-6">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <h6 className="text-primary text-uppercase mb-2">Why Choose Us!</h6>
                    <h1 className="head1 display-6 mb-4">Different Services we provide</h1>
                    {/* <p className="mb-5">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore erat amet</p> */}
                    <div className="row gy-5 gx-4">
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0 text-start">Training by Industry Experts, Project Based Training.</h5>
                            </div>
                         
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.2s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0 text-start">Complementary Resume building sessions.</h5>
                            </div>
                           
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0 text-start">LinkedIn and Naukri profile review by HR.</h5>
                            </div>
                          
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">Learning ,Training And Working.</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">Mock interview</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">Live Project Working</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">Course Completion Certificate</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">Weekly Assignment</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className=" icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">100% Placement Assistance</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">100% Notes Provide</h5>
                            </div>
                       
                        </div>
                    </div>
                    
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className=" overflow-hidden pe-5 pt-5 h-100" style={{"min-height": "400px"}}>
                        <img className=" w-100 h-100" src="image/whychooseus.jpg" alt=""/>
                        {/* <img className="position-absolute top-0 end-0 bg-white ps-3 pb-3" src="img/about-2.jpg" alt="" style={{width: "200px", height: "200px"}}/> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Features End --> */}


  
{/* questions start */}
<Container className='justify-content-center '>
    <h3 className='mt-3 mb-5'>Frequently Asked Questions ?</h3>
<Col > 

<Accordion defaultActiveKey="0">
<Accordion.Item eventKey="0">
<Accordion.Header className='logocolor1'>Does VTech Academy IT Training Institute provide placement assistance?</Accordion.Header>
<Accordion.Body>

Yes, VTech Academy IT Training Institute provides placement assistance to its students. 
{/* They have tie-ups with several companies in the IT industry and help their students in getting placed in these companies. */}
 
</Accordion.Body>
</Accordion.Item>
<Accordion.Item eventKey="1">
<Accordion.Header>Who can join the courses at VTech Academy IT Training Institute?
</Accordion.Header>
<Accordion.Body>
Anyone who is interested in learning IT skills can join the courses at VTech Academy IT Training Institute. 
There are no specific eligibility criteria for most of the courses.
  
</Accordion.Body>
</Accordion.Item>

<Accordion.Item eventKey="2">
<Accordion.Header> Does VTech Academy IT Training Institute offer any certifications?</Accordion.Header>
<Accordion.Body>

Yes, VTech Academy IT Training Institute offers certification programs for some of their courses. 
These certifications are recognized in the industry and can help you in your job search.
  
</Accordion.Body>
</Accordion.Item>

<Accordion.Item eventKey="3">
<Accordion.Header>What is the mode of training at  VTech Academy IT Training Institute?</Accordion.Header>
<Accordion.Body>


VTech Academy IT Training Institute offers both online and classroom training modes. 
You can choose the mode of training that suits you the best.
  
</Accordion.Body>
</Accordion.Item>


<Accordion.Item eventKey="4">
<Accordion.Header> What is the duration of the courses offered at VTech Academy IT Training Institute?</Accordion.Header>
<Accordion.Body>



The duration of the courses varies depending on the course you choose. 
Typically, the duration ranges from 4 months to 6 months.
  
</Accordion.Body>
</Accordion.Item>



<Accordion.Item eventKey="5">
<Accordion.Header> What is the fee structure of the courses at VTech Academy IT Training Institute?</Accordion.Header>
<Accordion.Body>



<p>The fee structure of the courses at VTech Academy IT Training Institute varies depending on the course you choose.
 You can contact them directly for more information on the fee structure.
 </p> 
</Accordion.Body>
</Accordion.Item>


<Accordion.Item eventKey="6">
<Accordion.Header className='bold'>  What courses does  VTech Academy IT Training Institute offer?</Accordion.Header>
<Accordion.Body>


VTech Academy IT Training Institute offers a wide range of courses in various IT domains, including:
<ListGroup>

<ListGroup.Item variant="primary">Java/J2EE</ListGroup.Item>
<ListGroup.Item variant="secondary">Python</ListGroup.Item>
<ListGroup.Item variant="success">AngularJS</ListGroup.Item>
<ListGroup.Item variant="danger">ReactJS</ListGroup.Item>
<ListGroup.Item variant="warning">Web Development</ListGroup.Item>
<ListGroup.Item variant="info">Database Management</ListGroup.Item>
<ListGroup.Item variant="light">Mobile Application Development
</ListGroup.Item>
<ListGroup.Item variant="dark">Data Science and Machine Learning</ListGroup.Item>

<ListGroup.Item variant="primary">Etc.</ListGroup.Item>
</ListGroup>
</Accordion.Body>
</Accordion.Item>
</Accordion>
</Col>
</Container>
{/* questions end */}

    </div>
  )
}
export default Home;