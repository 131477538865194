import './aboutus.css';
import React from 'react';
import { Card ,Col,Row,Container,Image} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { LuGoal } from "react-icons/lu";
import { GoGoal } from "react-icons/go";

export default function AboutUs() {
  return (
    <div>
         {/* <!-- Page Header Start --> */}
    <div className="container-fluid page-header py-6 my-6 mt-0 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center">
            <h1 className="display-4 text-white animated slideInDown mb-4">About Us</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb justify-content-center mb-0">
                    <li className="breadcrumb-item"><a class="text-white" href="/">Home</a></li>
                    <li className="breadcrumb-item text-primary active" aria-current="page">About</li>
                </ol>
            </nav>
        </div>
    </div>
    {/* <!-- Page Header End --> */}

           {/* <!-- About Start --> */}
    <div className="container-xxl py-6 ">
        <div className="container ">
            <div className="row g-5">
              
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="h-100">
                        <h6 className="text-primary text-uppercase mb-2">About Us</h6>
                        <h1 className="head1 display-6 mb-4">WHO WE ARE</h1>
                        <h5>VTech Academy Is All About Excellence</h5>
                        <p className='text-start'>We provide you all the programing language training and Job Oriented Placment Programs.VTech Academy is the best IT training institute in Pune. We provide cutting-edge training programs for individuals and organizations looking to expand their knowledge and abilities in the area of IT and software development. In order to satisfy the constantly changing needs of the industry, our institute offers a wide choice of courses that are taught by professionals with in-depth knowledge of their respective industries. We have best experienced team & visiting industry expert trainers. We serve online and classroom IT training in Pune.</p>
                    </div>
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className=" ps-5 pt-5 h-100" style={{"min-height": "400px"}}>
                        <img className=" w-100 h-100" src="image/studentquations.svg" alt=""/>
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- About End --> */}
  
    <Container className="my-5">
      <Row>
        <Col md={6} className="mb-4">
          <Card className="shadow mission-card">
          <LuGoal size={60} className='align-self-center mt-3 mb-1' />
            <Card.Body>
              <Card.Title className="about-head text-center text-wrap">Mission</Card.Title>
              <Card.Text>
                Our mission is to successfully place our enrolled students in the IT industry.
                We aim to place 100% of our students in the software industry by providing valuable knowledge to
                candidates for achieving their noble destiny.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-4">
          <Card className="shadow vision-card">
          <GoGoal size={60} className='align-self-center mt-3 mb-1' />
            <Card.Body>
              <Card.Title className="about-head text-center text-wrap">Vision</Card.Title>
              <Card.Text>
                Our vision is to smile the world through education.
                We aim to build confidence and pride in the Indian young generation.
                We believe that no one is a last bencher in college;
                everyone in life is a topper, just needing guidance and support from the world's best teachers.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    <div className='images1'>
        <div>
          <h2>Programming Languages & Tools Covered in  Full Stack Developer Course</h2>

          <Card.Img
            variant="top"
            src="./image1/java.png"
            style={{ width: '70px', height: '70px', margin: '10px' }}
          />
          <Card.Img
            variant="top"
            src="./image1/Angular.png"
            style={{ width: '70px', height: '70px', margin: '10px' }}
          /><Card.Img
            variant="top"
            src="./image1/aws.png"
            style={{ width: '70px', height: '70px', margin: '10px' }}
          /><Card.Img
            variant="top"
            src="./image1/Bootstrap.png"
            style={{width: '70px', height: '70px', margin: '10px' }}
          /><Card.Img
            variant="top"
            src="./image1/html.png"
            style={{ width: '70px', height: '70px', margin: '10px' }}
          /><Card.Img
            variant="top"
            src="./image1/eclipse.png"
            style={{width: '70px', height: '70px', margin: '10px' }}
          /><Card.Img
            variant="top"
            src="./image1/css.png"
            style={{ width: '70px', height: '70px', margin: '10px' }}
          /><Card.Img
            variant="top"
            src="./image1/js.png"
            style={{width: '70px', height: '70px', margin: '10px' }}
          />
          <Card.Img
            variant="top"
            src="./image1/junit.png"
            style={{width: '70px', height: '70px', margin: '10px' }}
          />
          <Card.Img
            variant="top"
            src="./image1/Mysql.png"
            style={{width: '70px', height: '70px', margin: '10px' }}
          />
          <Card.Img
            variant="top"
            src="./image1/python.png"
            style={{width: '70px', height: '70px', margin: '10px' }}
          />
          <Card.Img
            variant="top"
            src="./image1/React.png"
            style={{ width: '70px', height: '70px', margin: '10px' }}
          />
          <Card.Img
            variant="top"
            src="./image1/spring.png"
            style={{ width: '70px', height: '70px', margin: '10px' }}
          />
           <Card.Img
            variant="top"
            src="./image1/jupyter.png"
            style={{ width: '70px', height: '70px', margin: '10px' }}
          />
        
        </div>
      </div>
    {/* <!-- Team Start --> */}
    <div class="container-xxl py-6">
        <div class="container">
            <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{"max-width": "500px"}}>
                <h6 className="text-primary text-uppercase mb-2">Meet The Team</h6>
                <h1 className="head1 display-6 mb-4 ">Student Reviews</h1>
            </div>
            <div className="row g-0 team-items">
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                          
                        </div>
                        <div className="bg-white shadow text-center p-4">
                            <h5 className="mt-2">Lata Kutre</h5>
                            <hr></hr>
                            <div className='student-text'>Hii I'm Lata. I have completed Msc cs in 2024. I have completed a internship in frontend Technology at Vtech Infotech. Vtech className helped me a lot, because of that className I am in this position. Thank you so much Vtech staff...</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                          
                        </div>
                        <div className="bg-white shadow text-center p-4">
                            <h5 className="mt-2">Rutuja Tambade</h5>
                            <hr></hr>
                            <div className='student-text'>7th january, me and my friends first day in Vtech. The lecture was to start at 1 pm. We seat in the classroom and after a while vitthal sir entered. And sir took everyone's introduction...</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                         
                        </div>
                        <div className="bg-white shadow text-center p-4">
                            <h5 className="mt-2">Chaitanya Khandagale</h5>
                            <hr></hr>
                            <div className='student-text'>Hello Everyone!!! A truly great mentor is hard to find, difficult to part with, and impossible to forget. The above quote resonates very true with me. I joined Vtech with hope of breaking into IT field...</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="team-item position-relative">
                        <div className="position-relative">
                           
                        </div>
                        <div className="bg-white shadow text-center p-4">
                            <h5 className="mt-2">Vinayak Deshmukh</h5>
                            <hr></hr>
                            <div className='student-text'>Vtech is the best place for learning coding and for placement purposes. The faculty members are highly knowledgeable and passionate about teaching, and they provide in-depth training...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Team End --> */}


    <Container className='mt-5'>
      <Row className="align-items-center my-4">
        <Col md={4} className="text-center">
          <Image src="./image/serviceimg.png" alt="Team" fluid />
        </Col>
        <Col md={8}>
          <h2 className='head1'>Highlights of our Team Services</h2>
          <div className="highlight-text text-start">
            <p ><FontAwesomeIcon icon={faCheckCircle} className="check-icon" /> Vtech is the only company that focuses on imparting right knowledge, hands-on and best training.</p>
            <p><FontAwesomeIcon icon={faCheckCircle} className="check-icon" /> Our team focuses on making sure that each student gets personal attention, hence we keep the batch size smaller. Not like other institutes who just fill in batches with a large number of students.</p>
            <p><FontAwesomeIcon icon={faCheckCircle} className="check-icon" /> We ensure that you get LIVE project experience with us and we provide you with an experience certificate as applicable to your course.</p>
            <p><FontAwesomeIcon icon={faCheckCircle} className="check-icon" /> Vtech team’s best guiding process gives you a Unique learning experience to make sure that your knowledge of basic concepts becomes very strong so that you can face any technical challenges in life.</p>
            <p><FontAwesomeIcon icon={faCheckCircle} className="check-icon" /> We have a team of Trainers with a minimum of 10 years of coaching expertise to give you the Best learning experience.</p>
            <p><FontAwesomeIcon icon={faCheckCircle} className="check-icon" /> We are the Best IT Training and Placement institute in Pune because all our students are fully satisfied with the customized way of  trainers training and coaching them with an aim of helping them with a desired professional career.</p>
          </div>
        </Col>
      </Row>
    </Container>

    <Container className="d-flex justify-content-center mt-4">
      <Card className="shadow why-enroll-card text-wrap" style={{ maxWidth: '1400px'}}>
        <Card.Body>
          <Card.Title className="about-head text-center">Why Should You Enroll at Vtech Infotech?</Card.Title>
          <Card.Text className="paragraph ">
            At Vtech Infotech, we understand that enrolling in IT certification courses
            is an investment in your future. Therefore, we offer top-notch training programs that increase your potential, capacity, and performance and also provide you with the skills necessary to succeed in the IT industry.
            <br /><br />
            If you enroll in Vtech Infotech’s training programs, you can jumpstart your
            IT career by learning from experienced  and certified professionals at the best IT Training Institute in Pune.
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
    </div>
  )
}
