import './Login.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Row, Col, Container } from 'react-bootstrap';
import { FaFacebook } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc'; 
import  { useState } from 'react';
import axios from 'axios';
import API_BASE_URL from './Api';
import Alert from 'react-bootstrap/Alert';

export default function Login() {
  const [formData, setFormData] = useState({
    usernameOrEmail: '',
    password: ''
  });

  const [alert, setAlert] = useState({ show: false, variant: '', message: '' });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/api/auth/login`, formData);
      console.log('Login successful:', response.data);
      sessionStorage.setItem('token', response.data.accessToken); 
      console.log(sessionStorage.getItem('token'));

      setAlert({ show: true, variant: 'success', message: 'Login successful' });
      setTimeout(() => {
        navigate('/dashboard');
      }, 1500); 
    } catch (error) {
      console.error('Login failed:', error);
      setAlert({ show: true, variant: 'danger', message: 'Login failed.Please Check Email And Password.' });
    }
  };

  const handleClose = () => {
    setAlert({ show: false, variant: '', message: '' });
  };

  return (
    <div>
      <Container className='d-flex justify-content-center'>
        <Card className='card1 shadow' style={{ width: '24rem' }}>
          <Card.Title className='title text-center mt-3'>Sign In With</Card.Title>
          {alert.show && (
              <Alert variant={alert.variant} onClose={handleClose} dismissible>
                {alert.message}
              </Alert>
            )}
          <Row className='justify-content-center'>
            <Col xs='auto'>
              <Card className='card2 shadow'>
                <Card.Body className='d-flex align-items-center justify-content-center'>
                  <FaFacebook size={24} className='me-2 facebook' />
                  <div>Facebook</div>
                </Card.Body>
              </Card>
            </Col>
            <Col xs='auto'>
              <Card className='card3 shadow'>
                <Card.Body className='d-flex align-items-center justify-content-center'>
                  <FcGoogle size={24} className='me-2' />
                  <div>Google</div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="label mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control   type="email"
                  placeholder="Enter email"
                  name="usernameOrEmail"
                  value={formData.usernameOrEmail}
                  onChange={handleChange}/>
              </Form.Group>
              <Form.Group className="label mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control  type="password"
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange} />
              </Form.Group>
              <Button variant="primary" className='mt-2 mb-3 booknow' type="submit">
                Login
              </Button>
            </Form>
            <Card.Text>Not a member? <a href='#signup' className='about-head'>Sign Up Now</a></Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}
