import { Col, Container, Row } from 'react-bootstrap';
import './placement.css';
import React from 'react';
import {  FaStar } from 'react-icons/fa';

export default function Placement() {
  return (
    <div>
         {/* <!-- Page Header Start --> */}
    <div className="container-fluid page-header py-6 my-6 mt-0 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center">
            <h1 className="display-4 text-white animated slideInDown mb-4">Our Placement</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb justify-content-center mb-0">
                    <li className="breadcrumb-item"><a class="text-white" href="/">Home</a></li>
                    <li className="breadcrumb-item text-primary active" aria-current="page">Our Placement</li>
                </ol>
            </nav>
        </div>
    </div>
    {/* <!-- Page Header End --> */}

      <h2 className='about-head'>Placements @VTECH Academy</h2>
      <div className='paragraph'>
        <p className='mt-4 ms-5 text-start'>
          We understand that landing a job in the competitive IT industry can be challenging, which is why we offer comprehensive training programs that prepare our students for the real-world demands of the industry. Our programs are designed to provide hands-on experience in the latest technologies and tools that are in high demand by leading IT companies.
        </p>
        <p className='ms-5 text-start'>
          Our experienced trainers work closely with our students to ensure they have the necessary knowledge and skills to excel in their careers. We cover a wide range of topics, including programming languages, database management, cloud computing, cybersecurity, and more.
        </p>
        <p className='ms-5 text-start'>
          Our Placement Cell works tirelessly to identify job openings and facilitate placements for our students. We conduct regular placement drives and job fairs, where our students get the opportunity to interact with prospective employers and showcase their skills.
        </p>
        <p className='ms-5 text-start'>
          We have a proven track record of successfully placing our students in top IT companies across various industries, such as software development, web development, mobile app development, cybersecurity, and more. Our alumni are working in leading IT companies such as TCS, Infosys, Capgemini, IBM, Wipro, Cognizant, and many others.
        </p>
        <p className='ms-5 text-start'>
          At VTECH Academy, we believe in providing our students with a holistic learning experience that not only prepares them for their dream jobs but also equips them with the skills to grow and excel in their careers.
        </p>
        <p className='ms-5 text-start'>
          If you are looking for a training program that provides placement assistance and helps you launch your career in the IT industry, then VTECH Academy is the right choice for you. Contact us today to learn more about our training programs and placement assistance.
        </p>
      </div>

      <Container fluid className='placement-con'>
        <h3 className='head1 mt-5'>100% PLACEMENT ASSISTANCE</h3>
        <h5 className='about-head mt-3'>Students Placed With Companies</h5>
        <Row className='d-flex justify-content-center mt-4'>
          <Col xs={12} md={2} className='d-flex justify-content-center my-2'>
            <img src='./placementimage/accenture.png' alt='Accenture' className='img-fluid' />
          </Col>
          <Col xs={12} md={2} className='d-flex justify-content-center my-2'>
            <img src='./placementimage/amdocs.png' alt='Amdocs' className='img-fluid' />
          </Col>
          <Col xs={12} md={2} className='d-flex justify-content-center my-2'>
            <img src='./placementimage/bajaj.png' alt='Bajaj' className='img-fluid' />
          </Col>
          <Col xs={12} md={2} className='d-flex justify-content-center my-2'>
            <img src='./placementimage/bitwise.png' alt='Bitwise' className='img-fluid' />
          </Col>
          <Col xs={12} md={2} className='d-flex justify-content-center my-2'>
            <img src='./placementimage/bmc.png' alt='BMC' className='img-fluid' />
          </Col>
        </Row>
        <Row className='d-flex justify-content-center mt-4'>
          <Col xs={12} md={2} className='d-flex justify-content-center my-2'>
            <img src='./placementimage/capita.png' alt='Accenture' className='img-fluid' />
          </Col>
          <Col xs={12} md={2} className='d-flex justify-content-center my-2'>
            <img src='./placementimage/cognizant.png' alt='Amdocs' className='img-fluid' />
          </Col>
          <Col xs={12} md={2} className='d-flex justify-content-center my-2'>
            <img src='./placementimage/cybage.png' alt='Bajaj' className='img-fluid' />
          </Col>
          <Col xs={12} md={2} className='d-flex justify-content-center my-2'>
            <img src='./placementimage/hsbc.png' alt='Bitwise' className='img-fluid' />
          </Col>
          <Col xs={12} md={2} className='d-flex justify-content-center my-2'>
            <img src='./placementimage/infosys.png' alt='BMC' className='img-fluid' />
          </Col>
        </Row>
        <Row className='d-flex justify-content-center mt-4'>
          <Col xs={12} md={2} className='d-flex justify-content-center my-2'>
            <img src='./placementimage/infotech.png' alt='Accenture' className='img-fluid' />
          </Col>
          <Col xs={12} md={2} className='d-flex justify-content-center my-2'>
            <img src='./placementimage/mindtree.png' alt='Amdocs' className='img-fluid' />
          </Col>
          <Col xs={12} md={2} className='d-flex justify-content-center my-2'>
            <img src='./placementimage/paytm.png' alt='Bajaj' className='img-fluid' />
          </Col>
          <Col xs={12} md={2} className='d-flex justify-content-center my-2'>
            <img src='./placementimage/persistance.png' alt='Bitwise' className='img-fluid' />
          </Col>
          <Col xs={12} md={2} className='d-flex justify-content-center my-2'>
            <img src='./placementimage/tcs.png' alt='BMC' className='img-fluid' />
          </Col>
        </Row>
      </Container>

      <div>
        <h2 className='about-head'>WHAT OUR STUDENTS SAY'S</h2>
        <p className='mt-5 ms-5 text-center'>At VTECH Academy, we understand that enrolling in IT certification courses is an investment in your future. Therefore, we offer top-notch training programs that increase your potential, capacity, and performance and also provide you with the skills necessary to succeed in the IT industry.</p>
        <p className='ms-5 text-center'>If you enroll in VTECH Academy’s training programs, you can jumpstart your IT career by learning from experienced and certified professionals at the best IT Training Institute in Pune.</p>
        <div className="stars">
                        <FaStar size='25px'/>
                        <FaStar  size='25px'/>
                        <FaStar  size='25px'/>
                        <FaStar  size='25px'/>
                        <FaStar  size='25px'/>
                    </div>
      </div>
    </div>
  );
}
