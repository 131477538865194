import React, { useState } from 'react';
import { Form, Button, Container, Card } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import API_BASE_URL from '../Api'; 
import './ProductForm.css';


const ProductInformation = () => {
  const [formData, setFormData] = useState({
    productName: '',
    trainingMode: '',
    status: '',
    description: ''  // Added description field to match your comment about CKEditor
  });

  const [response, setResponse] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Uncomment this if you decide to use CKEditor
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData({
      ...formData,
      description: data
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.post(`${API_BASE_URL}/products/addProduct`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      alert(response.data.message);
      setFormData({
        productName: '',
        trainingMode: '',
        status: '',
        description: '' // Resetting description field if used
      });
      setResponse({ message: response.data.message });
    } catch (error) {
      console.error('Error submitting product form:', error);
      alert('Failed to submit product form. Please try again later.');
      setResponse({ message: 'Failed to submit product form.' });
    }
  };

  return (
    <Container className="product-form-container">
      <Card className="product-form-card">
        <Card.Body>
          <Card.Title>Product Information</Card.Title>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formProductName">
              <Form.Label className="form-label">Product Name</Form.Label>
              <Form.Control
                type="text"
                name="productName"
                value={formData.productName}
                onChange={handleChange}
                placeholder="Enter product name"
                required
              />
            </Form.Group>

            <Form.Group controlId="formTrainingMode">
              <Form.Label className="form-label">Mode of Training</Form.Label>
              <Form.Select
                name="trainingMode"
                value={formData.trainingMode}
                onChange={handleChange}
                required
              >
                <option value="">Select mode of training</option>
                <option value="online">Online</option>
                <option value="offline">Offline</option>
              </Form.Select>
            </Form.Group>

            {/* Uncomment this if you decide to use CKEditor */}
            <Form.Group controlId="formDescription">
              <Form.Label className="form-label">Description</Form.Label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.description}
                onChange={handleEditorChange}
              />
            </Form.Group>

            <Form.Group controlId="formStatus">
              <Form.Label className="form-label">Status</Form.Label>
              <Form.Select
                name="status"
                value={formData.status}
                onChange={handleChange}
                required
              >
                <option value="">Select Status</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Select>
            </Form.Group>

            <div className='mt-4'>
              <Button variant="primary" className='submit-btn' type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ProductInformation;
