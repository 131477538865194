import './Mernstack.css';
import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Card, Row,Col,Accordion } from 'react-bootstrap';
import { useState } from 'react';
import { FaCertificate, FaUserGraduate, FaBriefcase, FaProjectDiagram, FaChalkboardTeacher, FaHandshake, FaTasks, FaClock, FaStar } from 'react-icons/fa';
const MernStack = () => {

  const [, setClicked] = useState(false);

  const handleScrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setClicked(true); // Set clicked to true to trigger re-rendering
    };

  return (
    <div>
      {/* page header start*/}
       <div className="container-fluid page-header py-6 my-6 mt-0 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center">
            <h1 className="display-5 text-white animated slideInDown mt-4 mb-4">MERNSTACK TRAINING INSTITUTE IN PUNE</h1>
      </div>
      </div>
      {/* page header end */}
      {/* course overview start */}
            <div className="course-info mt-0">
                <h2 className='about-head'>FullStack MERN Course Overview</h2>
                <Container fluid className="display-text-container">
   
          <p className='course-overview'>
          The MERN stack is a popular and powerful web development framework that 
                combines four key technologies: MongoDB, Express.js, AngularJS (now known as Angular), and Node.js. This comprehensive course provides an in-depth exploration of the MERN stack, equipping students with the knowledge 
                and practical skills necessary to develop modern, full-stack web applications.
          </p>
     
    </Container>
                <br></br>
                <div className="enroll-now">
          <Link to='/enquiry'>
            <button className="enroll-now-button" onClick={handleScrollToTop}>Enroll Now</button>
          </Link>
          </div>
          </div>
          {/* course overview end */}
          {/* key highlights start */}
          <div className="key-highlights">
                <h2 className='head1'><b>Key Highlights</b></h2>
                <div className="highlight-grid">
                    <div className="highlight-item">
                        <FaCertificate size={80} />
                        <p>Certification</p>
                    </div>
                    <div className="highlight-item">
                        <FaUserGraduate size={80} />
                        <p>Mentor Support</p>
                    </div>
                    <div className="highlight-item">
                        <FaBriefcase size={80} />
                        <p>100% Job Assistance</p>
                    </div>
                    <div className="highlight-item">
                        <FaProjectDiagram size={80} />
                        <p>Projects and Assignments</p>
                    </div>
                    <div className="highlight-item">
                        <FaChalkboardTeacher size={80} />
                        <p>Interview Preparation</p>
                    </div>
                    <div className="highlight-item">
                        <FaHandshake size={80} />
                        <p>Hands-on Experience</p>
                    </div>
                    <div className="highlight-item">
                        <FaTasks size={80} />
                        <p>Comprehensive Coverage</p>
                    </div>
                    <div className="highlight-item">
                        <FaClock size={80} />
                        <p>Flexible Learning Options</p>
                    </div>
                </div>
            </div>
              {/* key highlights end */}
                {/* learn java  start */}
            <Container fluid className="display-text-container">
       <Card className="display-text-card">
        <Card.Body> 
            <div className="java-card">
                <h2 className='about-head'><b>Learn MERN</b></h2>
                <p> Our MERN Stack course offers comprehensive training on the popular web development stack comprising MongoDB, Express.
                js, Angular, and Node.js. Gain proficiency in building dynamic web applications using these cutting-edge technologies and frameworks.</p>
                <div className="rating">
                    <p>5.0</p>
                    <div className="stars">
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                    </div>
                    <p>332 reviews</p>
                </div>
                <div className="enroll-now">
                    <Link to='/enquiry'>
                    <button className="enroll-now-button" onClick={handleScrollToTop}>Enquiry Now</button>
                    </Link>
                   
                </div>
            </div>
          </Card.Body>
          </Card>
          </Container>
            {/* learn java end */}
            {/* java course overview start */}
            <div className="container-xxl py-6">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                    {/* <h6 className="text-primary text-uppercase mb-2">Why Choose Us!</h6> */}
                    <h1 className="head1 display-6 mb-4">MERN Stack Course Overview</h1>
                    <p className="course-overview">
                    The “MERN Stack Development” course provides comprehensive training on building web applications using the MERN stack, which is an acronym for MongoDB, Express.js, Angular, and Node.js. This course is suitable for individuals who want to become full-stack web developers or enhance their skills in developing modern web applications.
                            The course combines theoretical learning with hands-on practical exercises. Students will have access to online tutorials, code samples, and assignments. They will work on individual and group projects to apply the concepts learned and gain practical experience with the MERN stack. The course may also include guest lectures from industry experts and opportunities for networking.
                            Upon completing the course, students will have a strong foundation in MERN stack development. They will be proficient in building web applications using MongoDB as the database, Express.js as the back-end framework, Angular as the front-end framework, and Node.js as the server-side runtime environment. Students will be able to develop and deploy full-stack applications, implement RESTful APIs, and handle data flow between the client and server.
                    </p>
                    {/* <p className="mb-5">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore erat amet</p> */}
                    <div className="row gy-5 gx-4">
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0 text-start">MongoDB database integration</h5>
                            </div>
                         
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.2s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0 text-start">Angular framework for building dynamic web applications</h5>
                            </div>
                           
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0 text-start">Node.js runtime environment for server-side JavaScript</h5>
                            </div>
                          
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">Building RESTful APIs</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">Single-page application (SPA) development</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">User authentication and authorization</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">Front-end and back-end integration</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0 text-start">Data modeling and manipulation with MongoDB</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0 text-start">Full-stack application development using the MERN stack</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0">Performance optimization</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0 blink">Live Industry Projects Working</h5>
                            </div>
                       
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                            <div className="d-flex align-items-center mb-3">
                                <div className="icon1 flex-shrink-0 btn-square bg-primary me-3">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <h5 className="mb-0 blink">Internship(Free)</h5>
                            </div>
                       
                        </div>

                    </div>
                    
                </div>
                </div>
                </div>
                </div>     
                   {/* java course overview end */}
                      {/* course objective start */}
     <Container fluid className="course-objectives-container">
      <div className='course-objective'>
      <h2 className='about-head'>Course Objectives</h2>
      </div>
    

      <Row className="justify-content-center mt-4">
      
          <Card className="basic-java-card shadow" style={{ width: '70rem' }}>
            <Card.Body>
              <h1 className='head1'>BASIC OF MERN</h1>
              <hr />
              <h4>Course Duration: 100 HOURS</h4>
              <img src='./image/mern.png' alt='mern Logo' width='200px' />
              <Accordion>
                                    
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header><b>HTML & CSS</b></Accordion.Header>
                                            <Accordion.Body style={{ textAlign: 'left' }}>
                                                <ul style={{ paddingLeft: 0 }}>
                                            
                                                  <li> 1 About HTML JavaScript Training</li> 
                                                     <li> 2 HTML JavaScript Training Course Objective</li> 
                                                     <li> 3 Why Is The Course Different From Any Other ?</li> 
                                                     <li> 4 HTML Java Script Training Course Duration</li> 
                                                     <li> 5 HTML JavaScript Training Course Overview</li> 
                                                     <li> 5.1 HTML</li> 
                                                     <li> 5.1.1 Introduction</li> 
                                                     <li> 5.1.2 HTML Tags</li> 
                                                     <li> 5.1.3 Attributes</li> 
                                                     <li> 5.1.4 Events</li> 
                                                     <li> 5.1.5 Styling</li> 
                                                     <li> 5.1.6 Advanced HTML</li> 
                                                     <li> 5.1.7 HTML 5 tag reference-XHTML</li> 
                                                     <li> 5.2 CASCADING STYLE SHEETS</li> 
                                                     <li> 5.2.1 Introduction</li> 
                                                     <li> 5.2.2 Types of Stylesheets</li> 
                                                     <li> 5.2.3 Types of Selectors</li> 
                                                     <li> 5.2.4 Div’s & Span tags</li> 
                                                     <li> 5.2.5 Font</li> 
                                                     <li> 5.2.6 Text</li> 
                                                     <li> 5.2.7 INTRODUCTION TO CSS-3.0</li> 
                                                     <li> 5.3 JAVASCRIPT</li> 
                                                     <li> 5.3.1 Introduction to javascript</li> 
                                                     <li> 5.3.2 Document object model</li> 
                                                     <li> 5.3.3 Conditional statements</li> 
                                                     <li> 5.3.4 Loops</li> 
                                                     <li> 5.3.5 Window object Document object Arrays</li> 
                                                     <li> 5.3.6 Advanced JavaScript</li> 
                                                     <li> 5.3.7 Introduction to Jquery </li> 
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header><b>JavaScript Basics</b></Accordion.Header>
                                            <Accordion.Body style={{ textAlign: 'left' }}>
                                                <ul style={{ paddingLeft: 0 }}>
                                                   <li> JavaScript Basics</li>
                                                   <li> JavaScript Control Flow Statements</li>
                                                   <li> JavaScript Loops</li>
                                                   <li> JS Expression and Operators</li>
                                                    <li>JavaScript Objects</li>
                                                    <li>JavaScript Functions</li>
                                                    <li>JavaScript Regular Expression</li>
                                                    <li>JavaScript Events</li>
                                                    <li>JavaScript OOPs</li>
                                                   <li> JavaScript Inheritance and Prototype Chain</li>
                                                    <li>JavaScript Memory Management</li>
                                                    <li>JavaScript Promises</li>
                                                    <li>JavaScript Iterators and generators</li>
                                                    <li>JavaScript Validations</li>
                                                    <li>JavaScript Exception Handling</li>
                                                    <li>JavaScript Global Objects</li>
                                                    <li>DOM Manipulation</li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header><b>Basic Git and GitHub</b></Accordion.Header>
                                            <Accordion.Body style={{ textAlign: 'left' }}>
                                                <ul style={{ paddingLeft: 0 }}>
                                                    <li>Version Control</li>
                                                    <li>Repositories</li>
                                                    <li>Commit and Push</li>
                                                    <li>Branching and Merging</li>
                                                    <li>Collaboration</li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header><b>MongoDB - NoSQL Database</b></Accordion.Header>
                                            <Accordion.Body style={{ textAlign: 'left' }}>
                                                <ul style={{ paddingLeft: 0 }}>
                                                    <li>Introduction to MongoDB</li>
                                                    <li>CRUD Operations</li>
                                                    <li>Aggregation Framework</li>
                                                    <li>Indexing</li>
                                                    <li>Data Modeling</li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header><b>Express.js - Back-end Framework</b></Accordion.Header>
                                            <Accordion.Body style={{ textAlign: 'left' }}>
                                                <ul style={{ paddingLeft: 0 }}>
                                                    <li>Introduction to Express.js</li>
                                                    <li>Middleware</li>
                                                    <li>Routing</li>
                                                    <li>Template Engines</li>
                                                    <li>Error Handling</li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header><b>Angular - Front-end Framework</b></Accordion.Header>
                                            <Accordion.Body style={{ textAlign: 'left' }}>
                                                <ul style={{ paddingLeft: 0 }}>
                                                
                                                 <li>   1.1 AngularJS Training Objectives</li>
                                                    <li> 1.2 Who should do the course</li>
                                                    <li> 1.3 Prerequisites for Angular JS Course</li>
                                                    <li> 2 Angular JS Course Content</li>
                                                    <li> 2.1 AngularJS Introduction</li>
                                                    <li> 2.2 Execution flow</li>
                                                    <li> 2.3 Controllers</li>
                                                    <li> 2.4 Bootstrapping Angular APPS</li>
                                                    <li> 2.5 Data Binding</li>
                                                    <li> 2.6 Understanding AngularJS Architecture</li>
                                                    <li> 2.7 Dependency Injection</li>
                                                    <li> 2.8 Expressions</li>
                                                    <li> 2.9 Directives</li>
                                                    <li> 2.10 Filters</li>
                                                    <li> 2.11 Events</li>
                                                    <li> 2.12 Modules</li>
                                                    <li> 2.13 AngularJS XMLHttpRequest (AJAX)</li>
                                                    <li> 2.14 AngularJS Forms & Input Validation AngularJS Service Types</li>
                                                    <li> 2.15 Single Page Applications</li>
                                                    <li> 2.16 REST API Calls</li>
                                                    <li> 2.17 Animations</li>
                                                    <li> 2.18 Angular with UI Frameworks</li>
                                                    <li> 2.19 Behavior Driven Development</li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header><b>Node.js - JavaScript Runtime</b></Accordion.Header>
                                            <Accordion.Body style={{ textAlign: 'left' }}>
                                                <ul style={{ paddingLeft: 0 }}>
                                        
                                              <li>  1.1 Objectives of the Course</li>
                                                  <li> 1.2 Pre-Requisites of the Course</li>
                                                  <li> 1.3 Who should do the course</li>
                                                  <li> 2 NodeJS Training Content</li>
                                                  <li> 2.1 Introduction to Node.js</li>
                                                  <li> 2.2 Node.js Platform Setup</li>
                                                  <li> 2.3 Modules and npm</li>
                                                  <li> 2.4 The Callback Pattern</li>
                                                  <li> 2.5 Events</li>
                                                  <li> 2.6 Error Handling</li>
                                                  <li> 2.7 Buffers</li>
                                                  <li> 2.8 Streams</li>
                                                  <li> 2.9 Express.js</li>
                                                  <li> 2.10 Socket.io</li>
                                                  <li> 2.11 Connecting to Databases</li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="7">
                                            <Accordion.Header><b>Full-Stack Integration</b></Accordion.Header>
                                            <Accordion.Body style={{ textAlign: 'left' }}>
                                                <ul style={{ paddingLeft: 0 }}>
                                                    <li>Building RESTful APIs</li>
                                                    <li>Connecting Front-end with Back-end</li>
                                                    <li>Authentication and Authorization</li>
                                                    <li>Deployment Strategies</li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="8">
                                            <Accordion.Header><b>React Js</b></Accordion.Header>
                                            <Accordion.Body style={{ textAlign: 'left' }}>
                                                <ul style={{ paddingLeft: 0 }}>
                                                

                                                  <li>  1 ReactJS Training Overview</li>
                                                      <li> 1.1 Objectives of the Course</li>
                                                      <li> 1.2 Who should do the Course</li>
                                                      <li> 1.3 Prerequisites</li>
                                                      <li> 2 ReactJS Course Content</li>
                                                      <li> 2.1 Top-Level Content</li>
                                                     <li>  2.2 JavaScript</li>
                                                      <li> 2.3 React</li>
                                                      <li> 2.4 Node</li>
                                                      <li> 2.5 React with Node</li>
                                                      <li> 2.6 Integration of 3rd party Modules</li>
                                                     <li>  2.7 Others</li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                              
            </Card.Body>
          </Card>
       
      </Row>
    </Container>
        {/* course objective end */}
            {/* question start */}
    <Row>
      <Col>
 
                    <h2 className='about-head mt-5'>Who can apply for the course?</h2>
                    <h5 className='text-start ms-5'>The MERN Stack course is designed for individuals who want to learn 
                full-stack web development using the MERN Stack, which includes 
                MongoDB, Express.js, AngularJS (now Angular), and Node.js. 
                It is suitable for a variety of individuals, including <br></br>
          </h5> 
                    <br></br>
                    <br></br>
                    <Accordion className='ms-5'>
              <Accordion.Item eventKey="0">
                <Accordion.Header><b>Web Devloper</b></Accordion.Header>
                <Accordion.Body style={{ textAlign: 'left' }}>
                  <ul style={{ paddingLeft: 0 }}>
                    <li>Individuals with basic knowledge of web development technologies 
                        like HTML, CSS, and JavaScript who want to expand their skills to build dynamic
                         and robust web applications using the MERN Stack. </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header><b>Student</b></Accordion.Header>
                <Accordion.Body style={{ textAlign: 'left' }}>
                  <ul style={{ paddingLeft: 0 }}>
                    <li>Students pursuing degrees or certifications in computer science, software engineering, or related fields who want to gain practical skills in 
                        full-stack web development using MERN Stack technologies.      
                            </li></ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header><b>Experience MernStack Devloper</b></Accordion.Header>
                <Accordion.Body style={{ textAlign: 'left' }}>
                  <ul style={{ paddingLeft: 0 }}>
                    <li>Programmers who are already familiar with MernStack but want to deepen their knowledge and explore advanced topics and best practices.
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header><b>Professional Software Engineer</b></Accordion.Header>
                <Accordion.Body style={{ textAlign: 'left' }}>
                  <ul style={{ paddingLeft: 0 }}>
                    <li>Individuals who have no prior programming experience and want to start their programming journey with MernStack.
                      Professionals working in the software development industry who want to add MernStack to their skillset or update their MernStack knowledge.</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header><b>Enthusiasts And Self-Learner</b></Accordion.Header>
                <Accordion.Body style={{ textAlign: 'left' }}>
                  <ul style={{ paddingLeft: 0 }}>
                    <li>Individuals interested in programming and specifically Python, regardless of their professional background.</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>


            </Accordion>
             
      </Col>
      <Col>
      <div >
                    <img src="../image/girl1.png" height='500px' alt="Professionals" />
                </div>
      </Col>
    </Row>
        {/* question end */}
        <Container className='justify-content-center '>
    <h3 className='about-head mt-3 mb-5'>Frequently Asked Questions</h3>
    <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Who is this MERN Stack Development course suitable for?</Accordion.Header>
                        <Accordion.Body>
                            This course is suitable for both beginners and experienced developers who want to learn how to develop full-stack web applications using the MERN stack. It is ideal for web developers, software engineers, and anyone interested in learning modern web development technologies.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>What prerequisites are required to enroll in this course?</Accordion.Header>
                        <Accordion.Body>
                            To enroll in this course, students should have a basic understanding of HTML, CSS, and JavaScript. Familiarity with web development concepts and programming principles will be beneficial.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>How long is the course duration?</Accordion.Header>
                        <Accordion.Body>
                            The course duration is 100 hours.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Will I receive a certificate upon completion of the course?</Accordion.Header>
                        <Accordion.Body>
                            Yes, you will receive a certificate upon successful completion of the course.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>Are there any assessments or exams during the course?</Accordion.Header>
                        <Accordion.Body>
                            Yes, there will be assessments and exams during the course to evaluate your understanding and skills.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>What support will be provided during the course?</Accordion.Header>
                        <Accordion.Body>
                            You will receive mentor support, access to online tutorials, code samples, and assignments. You will also have opportunities for networking and guest lectures from industry experts.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>Will I be able to build real-world applications after completing this course?</Accordion.Header>
                        <Accordion.Body>
                            Yes, you will be able to build real-world applications using the MERN stack after completing this course.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header>Is job placement assistance provided after completing the course?</Accordion.Header>
                        <Accordion.Body>
                            Yes, job placement assistance is provided to help you find suitable job opportunities after completing the course.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
        </Container>

    </div>
  )
}
export default MernStack;