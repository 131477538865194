import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_BASE_URL from '../Api';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';

const AddInstituteDetail = () => {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    time: '',
    mobile: '',
    description: ''
  });

  const [response, setResponse] = useState(null);

  useEffect(() => {
    const fetchInstituteDetails = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await axios.get(`${API_BASE_URL}/company/getAllCompanyProfileDetails`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data && response.data.length > 0) {
          const instituteDetails = response.data[0];
          setFormData({
            name: instituteDetails.name || '',
            address: instituteDetails.address || '',
            time: instituteDetails.time || '',
            mobile: instituteDetails.mobile || '',
            description: instituteDetails.description || '',
          });
        }
      } catch (error) {
        console.error('Error fetching institute details:', error);
        alert('Unable to fetch existing data. You can still add new details.');
      }
    };

    fetchInstituteDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.post(`${API_BASE_URL}/company/addCompanyDetails`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      alert(response.data.message);
      setResponse({ message: response.data.message });
      // Removed fetchInstitutes call
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit form. Please try again later.');
    }
  };

  return (
    <div>
      <Container className="mt-5">
        <Row className="justify-content-md-center">
          <Col md="8" className="mx-auto">
            <Card className="shadow p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title className="text-center mb-4">Add Institute Detail</Card.Title>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="corseformName">
                    <Form.Label className='label'>Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="corseformName">
                    <Form.Label className='label'>Company Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formDuration">
                    <Form.Label>Time</Form.Label>
                    <Form.Control
                      type="text"
                      name="time"
                      value={formData.time}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formDate">
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      type="number"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formDate">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit" className="mt-3 w-100 submitbtn">
                    Submit
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddInstituteDetail;
